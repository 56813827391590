.canvas {
    width: 100%;
    height: 100%;
    position: absolute;
}

.playerArea {
    height: 100%;
    position: relative;
    overflow: hidden;
}

:global(.player-wrapper .canvas-container) {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;

    :global(canvas) {
        width: 100% !important;
        height: 100% !important;
    }
}

.showCanvas {
    :global(.canvas-container) {
        z-index: 3 !important;
    }
}

.controls {
    .seek {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;

        input {
            width: 100%;
            max-width: none;
        }
    }
    .buttons {
        display: grid;
        grid-template-columns: 110px 1fr 40px 110px 40px 1fr 110px;
        margin-top: 15px;

        .speed {
            button {
                background: none;
                border: none;
                color: #ccc;
            }
        }

        .time {
            position: relative;
            top: -10px;
            background: none;
            border: none;
            color: #ccc;
        }

        .play {
            justify-self: center;

            button {
                border-radius: 4px;
                border: solid 1px #3bce95;
                background: #3bce95;
                color: #fff;
                padding: 10px 30px;
                position: relative;
                top: -10px;
                min-width: 110px;
            }
        }
    }
}
