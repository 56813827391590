.TrainingDetails {
    margin-bottom: 64px;

    h2 {
        margin-top: 6px;
    }

    .name {
        font-size: 14px;
        line-height: 16px;
        margin: 30px 0 0 0;
        color: var(--gray);
    }

    .actions {
        list-style: none;
        padding: 0;
        margin: 10px 0 0;

        li {
            display: inline-block;
            margin-right: 20px;
        }

        i {
            position: relative;
            top: 3px;
            font-size: 20px;
        }
    }
}

.quickInfo {
    color: var(--dark-gray);
    font-size: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    margin-top: 20px;
}

.team {
    margin-top: 20px;
    text-align: center;
}

.coaches {
    color: var(--dark-gray);
    font-size: 12px;
}

.hide {
    display: none;
}

.score, .health {
    min-width: 70px;
}

.help {
    font-size: 12px;
    color: var(--gray);
    margin: 10px 0;
}

.addExercise {
    border: 1px var(--gray) dashed;
    border-radius: 4px;
    padding: 50px;
    text-align: center;
    color: var(--dark-gray);

    > span {
        display: inline-block;

        > span {
            display: flex;
            gap: 5px;
        }

        div {
            line-height: 23px;
            font-size: 12.5px;
        }
    }

    button {
        padding: 0;
        background: transparent;
        border: none;
    }
}

.disabled {
    color: var(--gray);

    button {
        &:hover {
            color: var(--gray);
        }
    }
}

.selectExercise {
    cursor: pointer;
    &:hover {
        color: var(--black-hover);
    }
}

.library {
    :global(.page-header ) {
        margin: 0;
    }

    h2 {
        display: none;
    }

    ul {
        margin: 0;
    }
}

.comment {
    cursor: pointer;
}

.accepted {
    color: var(--ok);
}

.review {
    color: var(--info);
}

.remake {
    color: var(--error);
}

.empty {
    padding: 20px 0 20px 18px;
    color: var(--black);
    background: var(--bg-blue);
}

.download {
    position: relative;
    top: 4px;
    margin-left: 5px;
}

@media only screen and (max-width : 480px) {
    .actions li {
        &:first-child {
            isplay: block;
            margin-bottom: 10px;
        }
    }

    .TrainingDetails {
        .quickInfo {
            grid-template-columns: 1fr 1fr;

            > div {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}



