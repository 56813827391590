.columns {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    gap: 20px;
}

.cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(5, auto) 1fr;
    gap: 15px;
}

.card {
    border: 1px solid var(--light-gray);
    background: var(--bg-blue);
}

.substitution {
    padding: 12px;

    .time {
        font-size: 10px;
        color: var(--accent);
    }

    .up {
        color: var(--black);
        line-height: 16px;
        margin: 5px 0;
        span {
            font-size: 16px;
            position: relative;
            top: 2px;
            margin-right: 5px;
        }
    }

    .down {
        color: var(--gray);
        line-height: 16px;
        span {
            font-size: 16px;
            transform: rotate(180deg);
            position: relative;
            top: 2px;
            margin-right: 5px;
        }
    }
}

.list {
    padding-bottom: 12px;

    > div {
        padding: 12px 12px 0 12px;

        span {
            font-size: 16px;
            position: relative;
            top: 2px;
            margin-right: 5px;
        }
    }
}

.addSubstitution {
    margin: 12px;

    button {
        margin: 0;
    }
}

