.columns21 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;
}

.columns11 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.columns111 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.columns12 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
}

.title {
    margin-bottom: 5px;
    color: #7986AF;
}

.photo {
    label {

    }

    img {
        width: 100%;
        height: auto;
        border: solid 1px #eee;
        border-radius: 4px;
    }
}
