.videoEditor {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 30px;
    height: calc(100vh - 230px);
}

.main {
    position: relative;
    height: 100%;

    .player {
        height: calc(100vh - 309px);
        > div {
            height: 100%;
            > section {
                height: 100%;
                > div:first-child {
                    height: calc(100vh - 309px);
                }
            }
        }
    }

    .scoreboard {
        margin: 30px 30px 0 30px;
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translate(-50%, 0);

        .bar {
            display: grid;
            grid-template-columns: auto 1fr 160px 1fr auto;
            background: #2e3032;
            color: #ccc;
            border-radius: 4px;

            .plus1 {
                line-height: 30px;
                padding: 0 10px;
                background: #27282a;
                cursor: pointer;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;

                span {
                    font-size: 16px;
                }
            }

            .plus2 {
                line-height: 30px;
                padding: 0 10px;
                background: #27282a;
                cursor: pointer;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;

                span {
                    font-size: 16px;
                }
            }

            .team1 {
                text-align: center;
                line-height: 30px;
                padding: 0 10px;
                word-break: break-all;
                height: 30px;
                overflow: hidden;
            }

            .scoreGroup {
                display: grid;
                grid-template-columns: 1fr 100px 1fr;
                height: 30px;
            }

            .score {
                text-align: center;
                font-size: 25px;
                line-height: 30px;
                cursor: pointer;
            }

            .time {
                line-height: 30px;
                background: #27282a;
                text-align: center;
                cursor: pointer;
            }

            .team2 {
                text-align: center;
                line-height: 30px;
                padding: 0 10px;
                word-break: break-all;
                height: 30px;
                overflow: hidden;
            }
        }
    }

    .ballTool {
        display: grid;
        position: relative;
        top: 2px;

        .line {
            border-top: 2px solid #2e3032;
            align-self: center;
        }

        .circle1 {
            border: solid 2px #2e3032;
            border-radius: 15px;
            width: 10px;
            height: 10px;
            align-self: center;
            cursor: pointer;
        }

        .circle2 {
            border: solid 2px #2e3032;
            border-radius: 15px;
            width: 10px;
            height: 10px;
            align-self: center;
            cursor: pointer;
        }

        .bigCircle {
            background: #fff;
            border-radius: 14px;
            position: relative;
            width: 18px;
            height: 18px;

            &:before {
                position: absolute;
                left: -2px;
                top: -1px;
            }
        }
    }

    .ballControlTeam1 {
        grid-template-columns: 1fr 18px 1fr 10px 1fr 10px 1fr;
        left: -4px;
    }

    .ballControlNone {
        grid-template-columns: 1fr 10px 1fr 18px 1fr 10px 1fr;
    }

    .ballControlTeam2 {
        grid-template-columns: 1fr 10px 1fr 10px 1fr 18px 1fr;
        left: 4px;
    }

    .eventsList {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 3;
        top: 1px;
        background: #37383beb;
        color: #ccc;
        padding: 0 20px 20px 20px;
        overflow-x: auto;

        h3 {
            clear: both;
            border-top: solid 1px #27282a;
            padding-top: 10px;
        }

        .eventGroup {
            display: inline-block;
            margin: 0 15px 5px 0;
            float: left;
        }

        .eventItem {
            background: #2e3032;
            padding: 10px;
            min-width: 100px;
            border-radius: 4px;
            display: inline-block;
            margin: 0 10px 10px 0;
            position: relative;
            cursor: pointer;
            text-align: center;

            &:hover {
                background: #27282a;
            }

            .switch {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;
                position: absolute;
                width: 100%;
                justify-items: center;
                left: 0;

                div {
                    height: 15px;
                    width: 40px;
                    border-radius: 4px;
                    border: solid 2px #2e3032;
                    cursor: pointer;
                }
            }

            .green {
                background: var(--primary);
                opacity: 0.5;
                justify-self: right;

                &:hover {
                    background: var(--primary);
                    opacity: 1;
                }
            }

            .red {
                background: var(--red);
                opacity: 0.5;
                justify-self: left;

                &:hover {
                    background: var(--red);
                    opacity: 1;
                }
            }
        }

        .eventSwitcher {
            cursor: auto;

            &:hover {
                background: #2e3032;
            }
        }
    }
}

.panel {
    width: 320px;
    color: #ccc;
    background: #2e3032;

    .toolbar {
        display: grid;
        grid-template-columns: 1fr 160px 1fr;
        border-bottom: solid 1px #27282a;
        padding: 0;
        list-style: none;

        .button {
            margin: 10px 0 0;
            font-size: 30px;
            padding: 0;
            cursor: pointer;
            text-align: center;

            span {
                font-size: 30px;

                &:before{
                    color: #9d9d9d;
                }
            }

            span:hover {
                &:before{
                    color: #ccc;
                }
            }
        }
    }

    .group {
        padding: 20px;
    }

    .columns2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    .buttons {
        text-align: right;
    }

    .separator {
        height: 20px;
        background: #242628;
    }

    .tabs {
        text-align: center;
        margin: 20px;
    }

    .active {
        color: var(--primary) !important;

        span {
            &:before {
                color: var(--primary) !important;
            }
        }
    }

    .editorList {
        padding: 0 20px 20px 20px;
        list-style: none;
        overflow: auto;
        height: calc(100vh - 300px);

        li {
            padding: 5px 0;
            border-bottom: solid 1px #27282a;
            margin-bottom: 5px;
            display: grid;
            gap: 10px;
            grid-template-columns: auto 1fr auto;
            cursor: pointer;

            span:before {
                font-size: 20px;
            }
        }

        .selected {
            span {
                color: #3BCE9D;

                &:before {
                    color: var(--primary) !important;
                }
            }
        }

        .ourTeam {
            margin-bottom: 15px;
        }

        .otherTeam {
            margin-top: 15px;
        }
    }

    .records {
        height: calc(100vh - 365px);
    }

    .drawToolbar {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        text-align: center;
        margin: 15px;
        gap: 15px;

        > div {
            cursor: pointer;
            padding: 10px 5px 7px;
            border-radius: 4px;

            span {
                &:before{
                    color: #9d9d9d;
                }

                &:hover {
                    &:before {
                        color: #ccc;
                    }
                }
            }

            .color {
                background: #9d9d9d;
                width: 20px;
                height: 20px;
                display: inline-block;
                margin: 2px 0 0 0;
                border: solid 1px #9d9d9d;
            }

            .disabled {

                &:before{
                    color: #27282a;
                }

                &:hover {
                    &:before {
                        color: #27282a;
                    }
                }
            }

            .color.disabled {
                border: solid 1px #27282a;
                background: #2e3032 !important;
            }
        }

        .selected {
            background: #242628;
        }
    }

    .belongThisVideo {
        color: var(--primary);
    }

    .actions {
        padding: 0 15px;

        button {
            width: 100%;
            margin: 0 0 10px 0;
        }
    }
}
