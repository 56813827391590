.status {
   min-width: 150px;
}

.roles {
    font-size: 12px;
}

.hide {
}

@media only screen and (max-width : 700px) {
    .hide {
        display: none;
    }
}
