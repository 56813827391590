.categoryGroup {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 15px;
}

.groupTitle {
    grid-column-start: 1;
    grid-column-end: -1;
    margin: 15px 0 -5px;
}

.category {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 4px;

    &:hover .image {
        opacity: 0.9;
        transform: scale(1.1);
    }

    .image {
        filter: brightness(0.5);
        opacity: 0.7;
        width: 100%;
        height: auto;
        transition: all .2s ease;
    }

    .title {
        color: var(--white);
        position: absolute;
        z-index: 1;
        text-align: center;
    }

}

.selectedCategory {
    .image {
        opacity: 0.9;
    }
}


.loadMore {
    text-align: center;
    margin: 10px 0 20px;
}

.tags {

}

.tag {
    border: solid 1px var(--second-bg);
    background: var(--second-bg);
    margin: 0 15px 15px 0;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    color: var(--primary);
    display: inline-block;

    &:hover {
        background: var(--white);
        border-color: var(--primary);
        color: var(--primary);
    }
}

.selectedTag {
    background: var(--primary);
    color: var(--white);

    &:hover {
        color: var(--dark-gray);
    }
}


.list {
    padding: 10px 0;
    margin-bottom: 15px;
}

.selected {
    border: solid 1px var(--primary);
    background: var(--primary);
    color: var(--white);
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;

    span {
        background: #fff;
        color: #ddd4ce;
        display: inline-block;
        width: 19px;
        border-radius: 10px;
        text-align: center;
        margin-left: 5px;
    }

    &:hover {
        background: var(--white);
        border-color: var(--primary);
        color: var(--primary);

        span {
            background: var(--primary);
            color: var(--white);
        }
    }
}

.exercises {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap:20px;
}

.exercise {
    background: var(--light-gray-hover);
    cursor: pointer;
    overflow: hidden;
    border-radius: 4px;

    &:hover .image {
        opacity: 0.9;
        transform: scale(1.1);
    }

    .preview {
        background-color: #f6f6f8;
        text-align: center;
        overflow: hidden;
        position: relative;
    }



    .image {
        width: auto;
        height: 100%;
        transition: all .2s ease;
    }

    .previewTags {
        position: absolute;
        bottom: 10px;
        left: 10px;
    }

    .previewTag {
        background: #F6F6F9;
        padding: 5px 5px;
        margin-right: 5px;
        border-radius: 4px;
        color: var(--primary);
    }

    .schemeBg {
        background-color: var(--primary);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            background-position: center;
            background-size: contain;
        }
    }

    .description {
        position: relative;
        background: var(--light-gray-hover);
        padding: 15px;
    }

    .title {
        font-weight: bold;
        height: 39px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        color: var(--black);
    }

    .announcement {
        height: 32px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        color: var(--text-gray);
        font-size: 12px;
    }

    .panel {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap:20px;
        color: var(--text-gray);
        margin-bottom: 5px;
        font-size: 13px;
        text-align: center;
        span {
            position: relative;
            top: 2px;
            &:before {
                font-size: 14px;
                color: var(--text-gray);
            }
        }
    }
}

@media only screen and (max-width : 700px) {
    .categoryGroup {
        grid-template-columns: repeat(3, 1fr);
    }

    .exercises {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width : 450px) {
    .categoryGroup {
        grid-template-columns: repeat(2, 1fr);
    }

    .exercises {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width : 1350px) {
    .exercises {
        grid-template-columns: repeat(6, 1fr);
    }
}
