.fileUpload2 {

    :global(.dzu-previewContainer) {
        padding: 20px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 100%;
        min-height: 60px;
        z-index: 1;
        border-bottom: 1px solid #ECECEC;
        box-sizing: border-box;
    }

    :global(.dzu-previewContainer) {
        display: flex;
        align-items: center;
    }

    :global(.dzu-previewButton) {
        background-size: 14px 14px;
        background-position: center;
        background-repeat: no-repeat;
        width: 14px;
        height: 14px;
        cursor: pointer;
        opacity: 0.9;
        margin: 0 0 2px 10px;
        display: inline-block;
    }

    :global(.dzu-dropzone) {
        border: 1px var(--gray) solid;
        border-radius: 4px;
        padding: 50px;
        text-align: center;
        color: var(--dark-gray);

        label {
            font-size: 14px;
            font-weight: normal;
        }
    }

    input {
        display: none;
    }

    img {
        width: auto;
        max-height: 40px;
        max-width: 140px;
        border-radius: 4px;
    }
}
