.modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 512px;
  box-shadow: 4px 4px 32px rgba(50, 74, 138, 0.2);
  position: absolute;
  border-radius: 4px;
}

.overlay {
  //backdrop-filter: blur(3px);
  background: #ffffffc7;
  position: fixed;
  z-index: 200;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.header {
  background: var(--bg);
  color: var(--dark-gray);
  font-size: 16px;
  font-weight: 500;
  padding: 16px 24px;
  position: relative;

  button {
    margin: 0;
  }
}

.closeButton {
  margin: 0;
  position: absolute;
  right: 20px;
  padding: 0 7px;
}

.body {
  background: var(--white);
  border-top: solid 1px var(--light-gray);
  border-bottom: solid 1px var(--light-gray);
  padding: 24px 24px 0 24px;
  max-height: calc(100vh - 132px);
  overflow: auto;
}

.noPadding {
  padding: 0;
}

.footer {
  background: var(--white);
  padding: 16px 24px;

  .footerButtons {
    display: grid;
    grid-template-columns: 1fr 3fr;

    button {
      margin-top: 0;
    }
  }

  .right {
    text-align: right;
  }
}

:global(.dark) {
  .overlay {
    background: #27282aeb;
  }
  .header {
    background: #27282a;
    color: #ccc;
  }
  .body {
    background: #242628;
    border-top: solid 1px #27282a;
    border-bottom: solid 1px #27282a;
  }
  .footer {
    background: #242628;
  }
  .closeButton {
    color: #ccc;
  }
}
