.error {
    color: var(--red);
    margin-bottom: 30px;
}

.center {
    text-align: center;
}

.support {
    font-size: 14px;
    background: var(--second-bg);
    border: solid 1px var(--second-bg);
    color: var(--primary);
    display: block;
    padding: 8px;
    border-radius: 4px;

    &:hover {
        background: var(--white);
        border: solid 1px var(--primary);
    }
}
