.row {
    display: grid;
    grid-template-columns: 5fr 1fr;
    align-items: center;
    color: var(--black);

    h3 {
        margin: 5px 0 0;
        font-size: 14px;
    }
}

.columns11 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
