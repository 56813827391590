.cards {
    margin: 30px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}

.card {
    background-color: var(--light-gray);
    border-radius: 4px;
    max-width: initial;
}

.hide {
}

.buttons {
    width: 120px;

    button {
        margin: 0;
    }
}

.group {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 40px;
    margin: 30px 0 64px;

    h2 {
        margin-top: 6px;
    }
}

.logo {
    padding: 24px;
    background: #fff;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    height: 200px;

    img {
        width: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.actions {
    list-style: none;
    padding: 0;
    margin: 35px 0 0;

    li {
        display: inline-block;
        margin-right: 20px;
    }

    i {
        position: relative;
        top: 3px;
        font-size: 20px;
    }
}

.rowLogo {
    width: 100px;

    img {
        width: auto;
        max-width: 100px;
        height: 50px;
    }
}

.stat {
    width: 60px;
}

@media only screen and (max-width : 700px) {
    .hide {
        display: none;
    }

    .buttons {
        width: auto;
    }
}

@media only screen and (min-width : 600px) {
    .cards {
        grid-template-columns: repeat(3, 1fr);
    }

}

@media only screen and (min-width : 1024px) {
    .cards {
        grid-template-columns: repeat(4, 1fr);
    }
}


@media only screen and (min-width : 1200px) {
    .cards {
        grid-template-columns: repeat(6, 1fr);
    }
}
