.panel {
    margin: 10px 0;
    border: solid 1px var(--light-gray);
    border-radius: 4px;
    padding: 15px;

    .children {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 15px;
        text-align: center;
    }
}

.toolbox {
    display: grid;
    gap: 10px;
    align-items: center;
}

.action {
    display: flex;
    gap: 10px;
}

.filterButton {
    display: none;
}

.noBorder {
    border: none;
    padding: 0;
}

.noAction > div:first-child {
    margin-left: 0;
}

.subBox {
    grid-column: 1/-1;

    span {
        display: inline-block;
    }
}

@media only screen and (max-width : 1024px) {
    .panel {
        .children {
            grid-template-columns: 1fr  1fr 1fr 1fr;
        }
    }
}

@media only screen and (max-width : 700px) {
    .panel {
        .children {
            grid-template-columns: 1fr 1fr;
        }
    }

    .toolbox {
        grid-template-columns: none !important;
    }

    .action {
        display: grid;
        grid-template-columns: auto 1fr;
    }

    .filterButton {
        display: inline-block;
        width: 48px;
        justify-self: right;
        padding: 11px 12px 7px;
        margin: 0;
    }

    .filtersHide {
        display: none;
    }

    .subFilters {
        order: 3;
    }

    .subBox  span {
        display: block;
    }

    .search {
        margin-top: 20px;
        order: 4;
    }
}

@media only screen and (min-width : 700px) {
    .subFilters {
        display: grid;
        grid-template-columns: repeat(6, auto);
    }

    .filters {
        :global(.datePicker) {
            display: inline-block;
            margin: 0 0 0 24px;
        }
    }

    .panel {
        :global(.react-datepicker-wrapper) {
            display: inline-block;
            width: 100px;
        }

        :global(.datePicker label) {
            display: inline-block;
            font-size: 12px;
            margin-right: 8px;
            font-weight: normal;
        }
    }
}
