body {
  --primary: #3BCE9D;
  --primary-light: #3bce9dc4;

  --secondary: #2E5FE6;
  --accent: #FFC31F;
  --dark-accent: #b98b10;
  --blue: #347FF6;
  --light-blue: #A3B9F5;
  --red: #F8571B;
  --dark-red: #cd4615;
  --yelow: #FDE55E;
  --black: #536493;
  --black-hover: #5a6586;
  --dark-gray: #7986AF;
  --gray: #B2B8CB;
  --light-gray: #EDF0F3;
  --light-gray-hover: #e4e6e8;
  --text-gray: #89898a;
  --menu-bg: linear-gradient(235.2deg, #EAEAF0 23.03%, #F3F3F7 85.19%), #EFEFF4;
  --second-bg: #F6F6F9;
  --bg: #F8FAFB;
  --bg-50: #EAEAF050;
  --bg-75: #EAEAF075;
  --bg-blue: #F5F9FF;
  --white: #FFFFFF;
  --green-hover: #2EB88A;
  --font-color: #7986AF;
  --a-color: #7986AF;
  --a-seleted-color: #347FF6;
  --disabled: #fafafa;

  --scrollbar-bg: #F5F5F5;
  --scrollbar: #ccc;

  --ok: #3BCE9D;
  --info: #FFC31F;
  --error: #F8571B;

  --font: verdana;
  --font-size: 16px;
  --line-height: 1.5em;

  --font-size-h1: 1.5em;
  --line-height-h1: 1.5em;

  --font-size-micro: 0.7em;
  --line-height-micro: 1em;

  --font-size-mini: 0.8em;
  --line-height-mini: 1.6em;

  --text-second-medium: 0.9em;

  --text-micro: 0.7em;
}

body {
  background: #F6F6F9;
  margin: 0;
  padding: 0;
  font-family: Verdana,Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
}

a {
  color: var(--a-color);
  text-decoration: none;
}

button {
  font-family: Verdana,Helvetica,Arial,sans-serif;
  font-size: 14px;
}

h1 {
  font-weight: bold;
  font-size: 1.5em;
  line-height: 1.5em;
  color: var(--black);
}

h2 {
  font-size: 1.3em;
  font-weight: 500;
  line-height: 1.1;
  color: var(--black);
}

fieldset {
  border: none;
  padding: 0;
}

 #ypd {
   display: grid;
 }

#appMenu {
  display: inline-block;
  background: linear-gradient(235.2deg, #EAEAF0 23.03%, #F3F3F7 85.19%), #EFEFF4;
}

#appContent {
  display: grid;
  grid-template-rows: auto auto 1fr;
  margin-bottom: 60px;
}

#appContent .page-header {
  padding: 20px 16px 0;
  max-width: calc(100vw - 38px);
  border: none;
  margin: 0;
}

#appContent .page-content {
  background: #FFFFFF;
  padding: 10px 16px;
  max-width: 100vw;
}

[class^="icon-"], [class*=" icon-"] {
  display: inline-block;
  font-size: 22px;
}

.icon-green:before {
  color: var(--primary);
}

.icon-yellow:before {
  color: var(--yelow);
}

.icon-red:before {
  color: var(--red);
}


@media only screen and (min-width: 600px) {
  #appContent {
    margin-bottom: 60px;
  }

  #appContent .page-header {
    padding: 20px 40px 0;
    max-width: 100vw;
  }

  #appContent .page-content {
    padding: 10px 40px;
    max-width: 100vw;
  }
}

@media only screen and (min-width: 992px) {
  #ypd {
    grid-template-columns: 300px 1fr;
    grid-template-rows: 1fr;
    gap: 0;
  }

  #ypd #appMenu nav {
    display: grid;
    grid-template-rows: 102px 1fr 120px;
    position: initial;
    z-index: initial;
  }

  #ypd .menu {
    display: none;
  }

  #ypd .header-bar {
    grid-template-columns: auto repeat(5, 50px);
    padding: 23px 40px 22px 60px;
  }

  #appContent {
    margin-bottom: 0;
  }

  #appContent .page-header {
    padding: 20px 48px 0 58px;
    max-width: calc(100vw - 306px);
  }

  #appContent .page-content {
    padding: 20px 48px 20px 58px;
    max-width: calc(100vw - 306px);
  }
}
