.WeakSelect {
    ul {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 5px;
        list-style: none;
        padding: 6px 0;
    }

    li {
        color: var(--black);
        display: block;
        background: var(--light-gray);
        width: 100%;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
        padding: 3px 0;
        font-size: 12px;
    }

    .selected {
        color: #fff;
        background: var(--light-blue);
    }

    label {
        margin-bottom: 0;
        padding: 0;
        display: block;
        font-size: 0.7em;
        color: var(--black);
    }
}
