
.row {
    display: grid;
    grid-template-columns: 1fr 120px;
    margin-bottom: 10px;
    align-items: center;

    div > div {
        margin-bottom: 0;
    }
}
