.attendance {
    margin-bottom: 64px;

    h2 {
        margin-top: 6px;
    }
}

.name {
    font-size: 14px;
    line-height: 16px;
    margin: 30px 0 0 0;
    color: var(--gray);
}


.r90 {
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}


.matrix {
    display: grid;
    margin: 30px 0;
    overflow-x: auto;
    overflow-y: hidden;

    .matrixRow {
        border-right: solid 1px #EDF0F4;
        color: #536493;

        .leftPanel {
            position: absolute;
            width: 150px;
            padding: 0px 10px 0px 18px;
            border-left: solid 1px #EDF0F4;
            border-right: solid 3px #EDF0F4;
            z-index: 2;
            height: 60px;
            display: grid;
            align-content: center;
            margin: auto auto;
            overflow: hidden;
        }

        .matrixCells {
            display: grid;

            .leftPanelSpacer {
                width: 150px;
            }

            div {
                padding: 20px 0 20px;
                text-align: center;
                border-left: 1px solid #EDF0F4;

                &:first-child {
                    border-left: none;
                    text-align: left;
                }

                &:last-child {
                    border-right: solid 1px #EDF0F4;
                }
            }

            .border {
                border-left: 3px solid #EDF0F4;
            }
        }

        .matrixCategory {
            display: grid;
            padding: 0 !important;
            cursor: pointer;

            div {
                &:first-child {
                    text-align: center;
                }
            }
        }

        &:nth-child(even) {
            background: #F5F9FF;

            .leftPanel {
                background: #F5F9FF;
            }
        }

        &:nth-child(odd) {
            background: #FFFFFF;

            .leftPanel {
                background: #FFFFFF;
            }
        }

        &:first-child {
            border-right: none;
        }

        &:nth-child(2) {
            border-top: solid 1px #EDF0F4;
            border-radius: 4px 4px 0 0;
            background: #F5F9FF;
        }

        &:last-child {
            border-bottom: solid 1px #EDF0F4;
            border-radius: 0 0 4px 4px;
        }
    }

    .matrixHeader {
        .leftPanel {
            border: 0;
        }

        .matrixCells {
            overflow: hidden;

            div {
                border: none !important;
                font-size: 12px;
                color: #B2B8CB;
                position: relative;
                left: 10px;
                text-align: left;
            }
        }
    }

    .event {
        transform: rotate(-90deg);
        //height: 70px;
    }

    .category {
        background: #eaeaf075;
    }

    .eventHeader {
        cursor: auto;
    }
}
