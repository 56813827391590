.basicView {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: solid 1px var(--gray);
    margin-bottom: 20px;

    h4, h5 {
        font-weight: bold;
    }

    img {
        width: 100%;
        height: auto;
    }

    .left {
        margin: 15px;
        padding: 10px;
    }

    .right {
        padding: 15px 30px;
        background: var(--bg);
    }
}

.info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-top: 1px solid var(--gray);
    border-left: 1px solid var(--gray);

    > div {
        border-right: 1px solid var(--gray);
        border-bottom: 1px solid var(--gray);
        background: var(--bg-blue);
        padding: 5px 10px;
    }
}

.results {
    border-left: 1px solid var(--gray);
    border-top: 1px solid var(--gray);
    border-right: 1px solid var(--gray);

    > div {
        display: grid;
        grid-template-columns: 2fr 1fr;
        background: var(--white);
        border-bottom: 1px solid var(--gray);

        &:nth-child(odd) {
            background: var(--bg-blue);
        }

        span {
            padding: 5px 10px;
        }

        input {
            border: none;
            border-left: 1px solid var(--gray);
            background: transparent;
            max-width: initial;
            padding: 5px 10px;
        }
    }
}


@media only screen and (max-width : 600px) {
    .left, .right {
        grid-column: 1/-1;
    }
}
