.tooltipContent {
  background: #fff;
  border-radius: 4px;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  color: #333;
  padding: 15px;
  min-width: 300px;

  .title  {
    font-size: 15px;
  }

  .dates {
    margin: 10px 0;

    span {
      font-size: smaller;
    }
  }

  .location {
    margin: 0;
  }

  .dot {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 8px;
    margin-right: 10px;
    position: relative;
    top: 1px;
  }
}