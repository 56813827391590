.competitions {
    margin-bottom: 64px;

    h2 {
        margin-top: 6px;
    }
}

.name {
    font-size: 14px;
    line-height: 16px;
    margin: 30px 0 0 0;
    color: var(--gray);
}

.columns11 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.columns21 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;
}
