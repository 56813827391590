.columns11 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.spaces {
    margin: 10px 0 15px;
}

.tableRow {

}

:global(.tbody) {
    .inputColumn {
        padding: 10px 5px;
        :global(.form-field) {
            margin: 0;
            input {
                padding: 5px;
            }
        }
    }

    .selectColumn {
        padding: 12px 5px;
    }
}

.nameColumn {
    width: 200px;
}

.checkBoxColumn {
    width: 30px;
}

.actions {
    display: flex;
}

.number {
    width: 50px !important;
    text-align: center;
    padding: 8px 5px !important;
}

.h3 {
    margin: 0;
}

.submenu {
    margin: 0 0 20px 0;
}

.thisWeek td {
    background: #0da8501f;
}

.downButton {
    width: 30px;

    span {
        transform: rotate(180deg);
    }
}

.upButton {
    width: 30px;
}

.hide {
}

.show {
}

@media only screen and (max-width : 700px) {
    .hide {
        display: none;
    }
}

@media only screen and (min-width : 700px) {
    .show {
        display: none;
    }
}

