.Languages {
    text-align: right;
    margin-top: 20px;
    position: relative;
    font-size: var(--text-micro);
    line-height: var(--line-height-micro);
    color: var(--dark-gray);

    .active {
        display: flex;
        cursor: pointer;
        justify-content: flex-end;
    }

    .chevron {
        font-size: 16px;
        margin-left: 5px;
    }

    .languageList {
        margin-top: 10px;
        display: none;
        z-index: 1;
        background: var(--second-bg);
        position: absolute;
        right: 0;
        padding: 7px 10px;
        border-radius: 4px;
        box-shadow: 0 10px 20px 0 rgba(158, 158, 158, 0.35);

        .language {
            display: block;
            padding: 8px;
            white-space: nowrap;
            cursor: pointer;
        }
    }

    .languageListShow {
        display: block;
    }
}
