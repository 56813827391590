.MatchesDetails {
    margin-bottom: 64px;

    h2 {
        margin-top: 6px;

        span {
            background: #fff;
            padding: 5px 10px;
            border: solid 1px #ddd;
            border-radius: 4px;
        }
    }

    .name {
        font-size: 14px;
        line-height: 16px;
        margin: 30px 0 0 0;
        color: var(--gray);
    }

    .quickInfo {
        color: var(--dark-gray);
        font-size: 12px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 5px;
        margin-top: 20px;
    }

    .actions {
        list-style: none;
        padding: 0;
        margin: 10px 0 0;

        li {
            display: inline-block;
            margin-right: 20px;
        }

        i {
            position: relative;
            top: 3px;
            font-size: 20px;
        }
    }
}

.legenda {
    margin: 40px 0 20px;
}

.editors {
    display: flex;
    gap: 10px;

    span, i {
        cursor: pointer;
    }
}

.mapHeader {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin-top: 30px;

    div:last-child {
        text-align: right;
    }

    .rotate {
        cursor: pointer;
    }
}

.rating {
    min-width: 45px;
}

.empty {
    color: #536493;
    padding: 20px 18px;
    background: #F5F9FF;
    border: solid 1px #edf0f3;
    border-radius: 4px;
    margin-top: 15px;
}

.playerTable {
    :global(.reserve:not(.reserve ~ .reserve)) {
        border-top: solid 3px #EDF0F4;
    }
}

.hideAssistant {
    *[data-id="g-assist"] {
        display: none;
    }
}

.showAssistant {
    *[data-id="g-assist"] {
        display: block;
    }
}


:global(*[data-id="g-goal"]) {
    :global(text) {
        fill: #fff;
    }
}

:global(*[data-id="g-assist"]) {
    :global(text) {
        fill: #fff;
    }
}
.removeLayout {
    text-align: center;
    color: var(--text-gray);
    cursor: pointer;
}

.hideRemoveLayout {
    display: none;
}

.fileName {
    display: flex;
    gap: 10px;
}

.center {
    text-align: center;
}

.matrix {
    display: grid;
    margin: 30px 0;
    overflow-x: auto;
    overflow-y: hidden;

    .matrixRow {
        border-right: solid 1px #EDF0F4;
        color: #536493;

        .leftPanel {
            position: absolute;
            width: 150px;
            padding: 0px 10px 0px 18px;
            border-left: solid 1px #EDF0F4;
            border-right: solid 3px #EDF0F4;
            z-index: 2;
            height: 60px;
            display: grid;
            align-content: center;
            margin: auto auto;
            overflow: hidden;
        }

        .matrixCells {
            display: grid;

            .leftPanelSpacer {
                width: 150px;
            }

            div {
                padding: 20px 0 20px;
                text-align: center;
                border-left: 1px solid #EDF0F4;

                &:first-child {
                    border-left: none;
                    text-align: left;
                }

                &:last-child {
                    border-right: solid 1px #EDF0F4;
                }
            }

            .border {
                border-left: 3px solid #EDF0F4;
            }
        }

        .matrixCategory {
            display: grid;
            padding: 0 !important;
            cursor: pointer;

            div {
                &:first-child {
                    text-align: center;
                }
            }
        }

        &:nth-child(even) {
            background: #F5F9FF;
            .leftPanel {
                background: #F5F9FF;
            }
        }

        &:nth-child(odd) {
            background: #FFFFFF;
            .leftPanel {
                background: #FFFFFF;
            }
        }

        &:first-child {
            border-right: none;
        }

        &:nth-child(2) {
            border-top: solid 1px#EDF0F4;
            border-radius: 4px 4px 0 0;
            background: #F5F9FF;
        }

        &:last-child {
            border-bottom: solid 1px #EDF0F4;
            border-radius: 0 0 4px 4px;
        }
    }

    .matrixHeader {
        .leftPanel {
            border: 0;
        }
        .matrixCells {
            overflow: hidden;
            div {
                border: none !important;
                font-size: 12px;
                color: #B2B8CB;
                position: relative;
                left: 10px;
                text-align: left;
            }
        }
    }

    .event {
        transform: rotate(-90deg);
        //height: 70px;
    }

    .category {
        background: #eaeaf075;
    }

    .eventHeader {
        cursor: auto;
    }
}

.videoList {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;

    > div > div:first-child {
        min-height: 130px;
        background: #eee;
        margin-bottom: 10px;

        img {
            width: 100%;
            height: auto;
        }
    }
}

.mainTeam td {
    background: #0da8501f;
}
