.header {
    display: grid;
    grid-template-columns: 1fr 207.33px;
    gap: 15px;
}

.filters {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    margin-bottom: 15px;

    > div {
        margin-bottom: 0;
    }
}

.category {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 4px;

    &:hover .image {
        opacity: 0.9;
        transform: scale(1.2);
    }
}

.selectedCategory {
    .image {
        opacity: 0.9;
    }
}

.categoryGroup {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
}

.groupTitle {
    grid-column-start: 1;
    grid-column-end: -1;
    margin: 15px 0 -5px;
}


.selectedCategory {
    .image {
        opacity: 0.9;
    }
}

.scroll {
    overflow-y: auto;
    height: 456px;
    padding: 0 15px 10px;
    border: solid 1px #EDF0F3;
}

.image {
    filter: brightness(0.5);
    opacity: 0.7;
    width: 100%;
    height: auto;
    transition: all .3s ease;
}


.title {
    color: var(--white);
    position: absolute;
    z-index: 1;
}

.bigList {
    list-style: none;
    margin: 30px;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    gap: 15px;
}

.bigItem {
    border: solid 1px var(--second-bg);
    border-radius: 4px;
    max-width: 150px;
    min-height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: var(--second-bg);
    color: var(--primary);
    cursor: pointer;
    padding: 10px;

    > div {
        margin-bottom: 0;
        border: none;
        background: none;
    }

    span:before {
        color: var(--primary);
    }

    &:hover {
        background: var(--white);
        border-color: var(--primary);
        color: var(--primary);
    }
}

.tag {
    border: solid 1px var(--second-bg);
    background: var(--second-bg);
    margin: 0 15px 15px 0;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    color: var(--primary);
    display: inline-block;

    &:hover {
        background: var(--white);
        border-color: var(--primary);
        color: var(--primary);
    }
}

.selectedTag {
    background: var(--primary);
    color: var(--white);
}

.selectedScheme {
    background: var(--primary);
    color: var(--white);

    span:before {
        color: var(--white);
    }

    &:hover {
        span:before {
            color: var(--primary);
        }
    }
}

.addTag {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 15px;

    button {
        margin: 0;
        padding: 7px 15px;
    }
}

.addTagButton {
    padding-top: 13px;
}

.submenu {
    margin: 0 0 20px 0;
}

@media only screen and (max-width : 700px) {
    .bigList {
        margin: 30px 0;
    }

    .bigItem {
        max-width: none;
        min-height: initial;

        div > div {
            padding: 0;
        }
    }
}
