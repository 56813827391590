.bg {
    width: 100%;
    position: absolute;
    top: 200px;
    border: 50px;
    height: calc(100vh - 250px);
    background-size: auto calc(100% - 250px);
    background-position: left 60px bottom 0;
    background-color: #f8fafb;
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: 0.6;
}

.area {
    display: grid;
    grid-template-columns: 30px 1fr 30px;
    position: absolute;
    width: 100%;
    height: 100vh;
}

.left {
    .header, .copy {
        display: none;
    }
}

.main {
    background: var(--white);
    box-shadow: 4px 4px 32px rgb(50 74 138 / 5%);
    padding: 96px 30px 24px 30px;
    overflow: auto;
}

.right {
    .empty, .links {
        display: none;
    }
}

.header {
    margin: 64px 0 0 64px;

    .subtitle {
        max-width: 381px;
        font-size:  var(--text-second-medium);
        color:  var(--font-color);
        margin-right: 15px;
    }
}

.copy {
    margin: 0 15px 24px 64px;
    font-size: var(--text-micro);
    line-height: var(--line-height-micro);
    color: var(--dark-gray);

    a {
        color: var(--dark-gray);
    }
}

.links {
    margin: 0 64px 24px 0;
    list-style: none;
    padding: 0;
    line-height: var(--line-height-micro);
    text-align: right;

    li {
        display: inline-block;
        margin-left: 15px;
    }

    a {
        font-size: var(--text-micro);
        color: var(--dark-gray);
    }
}

.header2 {
    display: block;
}

.footer2 {
    margin: 24px 0;
    font-size: var(--text-micro);
    line-height: var(--line-height-micro);
    color: var(--dark-gray);
    text-align: center;

    a {
        color: var(--dark-gray);
    }
}

@media only screen and (min-width : 768px) {
    .bg {
        background-image: url('../../../../public/assets/images/registration-players.svg');
    }

    .area {
        grid-template-columns: 1fr 3fr 1fr;
    }

    .left {
        display: grid;
        grid-template-rows: 1fr auto;

        .header, .copy {
            display: block;
        }
    }

    .right {
        display: grid;
        grid-template-rows: 1fr auto;

        .empty, .links {
            display: block;
        }
    }

    .header2, .footer2 {
        display: none;
    }
}

@media only screen and (min-width : 970px) {
    .area {
        grid-template-columns: 32% 36% 32%;
    }

    :global(.right) {
        .area {
            grid-template-columns: 32% 32% 36%;
        }
    }
}


@media only screen and (min-width : 2000px) {
    .area {
        grid-template-columns: 37% 26% 37%;
    }

    :global(.right) {
        .area {
            grid-template-columns: 37% 37% 26%;
        }
    }

    .registration {
            font-size: 20px;
    }
}

@media only screen and (min-width : 3000px) {
    .registration {
            font-size: 26px;
    }
}
