.radioList {
    margin-bottom: 15px;

    .item {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 5px;
        align-items: center;
    }

    label {
        font-size: var(--font-size-mini);
        line-height: var(--line-height-mini);
    }

    input {
        margin: 0;
        padding: 0;
    }
}

.invalid {
    label {
        color: var(--red);

        a: {
            color: var(--red);
        }
    }
}

.inline {
    display: inline-block;
    margin-right: 15px;
}

