.coaches {
    margin-bottom: 64px;

    h2 {
        margin-top: 6px;
    }
}

.name {
    font-size: 14px;
    line-height: 16px;
    margin: 30px 0 0 0;
    color: var(--gray);
}

.pointer {
    cursor: pointer;
}
