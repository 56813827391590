.slider {
    position: relative;
}

.controls {
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    height: 100%;
    position: absolute;
    z-index: 5;
    width: 100%;
    align-items: center;
    justify-items: center;
}

.arrow {
    cursor: pointer;
}
