.header {
    display: grid;
    grid-template-columns: 1fr auto;

    button {
        align-self: center;
        color: var(--dark-gray);
        display: flex;
        border: none;
        background: transparent;
        cursor: pointer;
    }
}
