.HeaderBar {
  display: grid;
  grid-template-columns: 50px auto 50px;

  background: #FFFFFF;
  box-shadow: 4px 4px 32px rgba(50, 74, 138, 0.05);
  margin: 16px;
  padding: 16px;
  align-items: center;

  .chat, .calendar, .notifications, .help {
    display: none;
  }

  .settings {
    justify-self: end;
  }
}

.profile {
  width: 32px;
  height: 32px;
  display: inline-block;
  background-size: cover;
  border-radius: 18px;
  cursor: pointer;
}

.actor {
  border: solid 2px var(--red);
}

.red {
  color: var(--red);
}

.calendar {
  cursor: pointer;
}

.submenu {
  background: #fff;
  border-radius: 4px;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  color: #333;
  padding: 15px 15px 5px 15px;
  min-width: 300px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .item {
    padding: 5px;
    margin: 5px 0;
    cursor: pointer;

    a {
      display: block;
    }

    i {
      position: relative;
      top: 3px;
      margin: 0 10px 0 0;
    }

    &:hover {
      background: var(--bg-50);
      border-radius: 4px;

      a {
        text-decoration: none;
      }
    }
  }



  .account {
    display: grid;
    grid-template-columns: 50px auto;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: solid 1px #ccc;
    padding-bottom: 10px;
    gap: 10px;

    .photo {
      display: inline-block;
      width: 50px;
      height: 50px;
      background-size: cover;
      border-radius: 25px;
    }

    b {
      display: block;
    }
  }
}

@media only screen and (min-width: 600px) {
  .HeaderBar {
    grid-template-columns: 50px auto repeat(5, 50px);
    padding: 17px 40px 17px 40px;
    box-shadow: none;
    border-radius: initial;
    margin: 0;

    .chat, .calendar, .notifications, .help {
      display: inline-block;
      justify-self: end;
    }
  }
}
