

.map {
    position: relative;
}

.image {
    width: 100%;
    height: auto;
}

.canvas {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
}
