.basicForm {
    display: grid;
    grid-template-columns: 400px 1fr;
    gap: 0 30px;
    padding: 20px 20px 0 20px;
    border: solid 1px var(--gray);
    border-radius: 4px;
    margin-bottom: 20px;
}

.image {
    width: 100%;
    height: auto;
    transition: all .3s ease;
}

.filters {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    margin-bottom: 15px;

    > div {
        margin-bottom: 0;
    }
}

.bigList {
    list-style: none;
    margin: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    gap: 15px;
}

.left {
    text-align: center;
}

.load {
    margin: 10px;
    font-size: 12px;
    color: var(--black);
}

.edit {
    margin: 7px 0 0 0;
}

.footer Button {
    margin: 0 0 20px 15px;
}

.footerSave {
    text-align: right;

    .edit {
        display: none;
    }
}

.schemeBg {
    img {
        background-position: center;
        background-size: contain;
    }
}

@media only screen and (max-width : 1300px) {
    .basicForm {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width : 600px) {
    .left button {
        display: none;
    }

    .basicForm {
        grid-template-columns: auto 1fr;
    }

    .basicForm .delete button {
        margin-left: 0;
    }


    .left, .right {
        grid-column: 1/-1;
    }

    .footerSave .edit {
        display: inline-block;
    }
}

