.PanelItem {
  color: var(--black);

  b {

  }

  div {
    font-size: 12px;
  }
}
