.logo {
  display: inline-block;
  background: #eee;
  border-radius: 100px;
  width: 170px;
  height: 170px;
  position: relative;
  margin: 20px;

  img {
    position: absolute;
    top: -15px;
    left: -10px;
  }
}

.center {
  text-align: center;
}

.emptyTitle {
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.emptyDescription {
  font-size: 14px;
  line-height: 18px;
}
