.DropdownButton {
    display: inline-block;
    height: 36px;

    :global(.DdButton__indicator) {
        svg {
            height: 18px;
        }
    }
}

.button {
    padding-left: 10px;
    span {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 8px;
    }
}

.primary :global(.DdButton__control) {
    background: var(--primary);
    color: var(--white);
    min-height: auto;
    border: solid 1px #F6F6F9;

    span {
        i:before {
            color: #fff;
        }
    }
}

.primary:hover  {
    :global(.DdButton__control) {
        background: var(--green-hover);
        text-decoration: none;
        color: var(--white);

        &:hover {
            border: solid 1px #F6F6F9;
            box-shadow: none;
        }

        svg {
            color: #fff;
        }
    }
}

.second :global(.DdButton__control) {
    background: var(--second-bg);
    color: var(--primary);
    min-height: auto;
    border: solid 1px #F6F6F9;

    span {
        i:before {
            color: var(--primary);;
        }
    }

    svg {
        color: var(--primary);
    }
}

.second:hover  {
    :global(.DdButton__control) {
        background: var(--second-bg);
        text-decoration: none;
        color: var(--primary);

        &:hover {
            border: solid 1px var(--primary);
            box-shadow: none;
        }
    }
}
