.imageUpload {
    border: 1px var(--light-gray) solid;
    border-radius: 4px;
    margin-bottom: 15px;

    text-align: center;
    color: var(--dark-gray);
    background: var(--bg);

    > div {
        padding: 50px;
    }

    img {
        width: 100%;
        height: auto;
    }
}

label {
    margin-bottom: 0;
    padding: 0;
    display: block;
    font-size: var(--font-size-micro);
    color: var(--dark-gray);
}
