.description {
    margin: 16px 0 56px;
    font-size: var(--font-size);
    line-height: var(--line-height);
    color: var(--black);
}

.other {
    border-top: solid 1px var(--gray);
    text-align: center;
    font-size: var(--font-size);
    line-height: var(--line-height);

    span {
        background: var(--white);
        position: relative;
        top: -12px;
        padding: 0 10px;
    }
}
