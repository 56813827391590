.Switch {
    display: grid;
    grid-template-columns: auto 1fr;
    margin-bottom: 10px;

    label {
        font-size: 12px;
        color: var(--black);
        font-weight: normal;
        margin-right: 15px;
    }

    .fullSize {
        justify-self: right;
    }
}

:global(.react-switch-handle) {
    transition: 0s !important;
}
