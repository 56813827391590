.submenu {
    list-style: none;
    padding: 0;

    li {
        color: #536493;
        padding: 15px 18px;
        background: #F5F9FF;
        margin-bottom: 2px;
        border-radius: 4px;
        font-size: 15px;
        font-weight: normal;
    }
}
