.team {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 40px;
  margin-bottom: 64px;

  h2 {
    margin-top: 6px;
  }
}

.name {
  font-size: 14px;
  line-height: 16px;
  margin: 30px 0 0 0;
  color: var(--gray);
}

.info {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 16px;
  margin-bottom: 60px;
}

.actions {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;

  li {
    display: inline-block;
    margin-right: 20px;
  }

  i {
    position: relative;
    top: 3px;
    font-size: 20px;
  }
}

.number {
  width: 35px;
  font-size: 14px;
  color: var(--gray);
}

.photo {
  padding: 24px;
  background: #fff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  height: 200px;

  img {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width : 700px) {
  .hide {
    display: none;
  }
}

@media only screen and (max-width : 600px) {
  .photo img {
    width: 100px;
    height: auto;
  }
}

@media only screen and (max-width : 450px) {
  .team {
    margin-bottom: 40px;
    display: block;
  }

  .photo {
    text-align: center;

    img {
      width: 100px;
      height: auto;
    }
  }
}
