.status {
    text-transform: capitalize;
}

.submenu {
    margin: 0 0 20px 0;
}

.hide {
    display: inline-block;
}

@media only screen and (max-width : 700px) {
    .hide {
        display: none;
    }
}
