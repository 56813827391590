.matrix2 {
    min-width: 100%;
    overflow-x: auto;
    overflow-y: visible;
    padding: 0;

    .table {
        display: grid;
    }

    .header {
        display: grid;
        //grid-template-columns: 170px repeat(5, 90px) 1fr;

        .cell {
            align-self: end;
            margin-bottom: 8px;
            font-size: 12px;
            color: #B2B8CB;
            line-height: 1.6em;
            font-weight: normal;
            text-align: center;
            align-self: center;
        }
    }

    .row {
        display: grid;
        //grid-template-columns: 170px repeat(5, 90px) 1fr;
        border-top: 1px solid #EDF0F3;

        .cell {
            padding: 15px;
            text-align: center;
            border-right: 1px solid #EDF0F3;
        }
    }

    .rowHeader {
        position: sticky;
        left: 0;
        z-index: 1;
        padding: 15px;
        color: #536493;
        background: #F6F6F9;
        box-shadow: 2px 3px 3px 0px rgba(34, 60, 80, 0.2);
    }

    .row:nth-child(even) {
        background: #F5F9FF;
    }

    .header:first-child .rowHeader {
        background: none;
        box-shadow: none;
        align-self: end;
        padding: 20px 0 8px 15px;
        font-size: 12px;
        color: #B2B8CB;
        line-height: 1.6em;
        font-weight: normal;
        background: #fff;
    }

    :global(.tbDropdown__value-container) {
        justify-content: center;
    }

    :global(.tbDropdown__value-container span) {
        margin-right: 0;
    }
}

.r90 {
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}
