.columns21 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;
}

.columns11 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.columns111 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.columns12 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
}


.title {
    margin-bottom: 5px;
    color: #7986AF;
}

.button {
    margin: 0 15px 15px 0;
}

.text {
    padding: 0 0 15px 0;
}

.parents {
    table {

        tr {
            td {
                &:first-child {
                    padding: 10px 10px 10px 10px;
                }
            }
        }

        .column {
            padding: 10px 10px 10px 0;

            div {
                margin: 0;
            }

            input {
                min-width: 100px;
            }
        }

        th {
            padding: 8px 10px !important;
        }
    }
}

.submenu {
    margin: 0 0 20px 0;
}

.minHeight {
    min-height: 150px;
}

.action {
    display: flex;
    gap: 10px;
}



