.row21 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 15px;
}

.row11 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 15px;
}