.Textarea {
    margin-bottom: 15px;
    font-weight: 500;
}

.Textarea.form-field {
    margin-bottom: 16px;
}

.Textarea label {
    margin-bottom: 0;
    padding: 0;
    display: block;
    color: var(--dark-gray);
    font-size: var(--font-size-micro);
}

.Textarea textarea {
    border: 1px solid var(--light-gray);
    box-sizing: border-box;
    border-radius: 4px;
    font-size: var(--font-size-mini);
    line-height: var(--line-height-mini);
    padding: 8px 14px;
    width: 100%;
    outline: none;
    transition: all 300ms ease-in-out;
    color: var(--black);
    max-width: 100%;

    &::placeholder {
        color: var(--gray);
    }
}

.Textarea span {
    color: var(--red);
    font-size: 12px;
    font-weight: bold;
}

.Textarea.invalid label {
    color: var(--red);
}

.Textarea.invalid textarea {
    border: 1px solid var(--red);
}

.Textarea textarea:focus {
    border: 1px solid var(--light-blue);
}

.Textarea textarea:hover {
    border: 1px solid var(--blue);
}

.Textarea textarea:active {
    border: 1px solid var(--light-blue);
}
