.tabs {
  list-style: none;
  padding: 0;
  margin: 0;
  background: var(--bg);
  display: inline-block;
  border-radius: 4px;
  font-size: 12px;

  li {
    display: inline-block;
    padding: 9px 12px;
    cursor: pointer;
    color: var(--dark-gray);
  }

  .active {
    background: var(--light-blue);
    border-radius: 4px;
    color: white;
  }
}

:global(.dark) {
  .tabs {
    background: #333538;
  }

  .active {
    background: #333538;
  }
}
