.row12 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 0 15px;
}

.human {
    width: 100%;
    height: auto;
}
