.login {
    font-size: var(--font-size-mini);
    line-height: var(--line-height-mini);
    color: var(--dark-gray);
    margin-bottom: 32px;

    a {
        margin-left: 8px;
    }
}
