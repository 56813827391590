.header {
    text-align: center;
}

.row {
    display: grid;
    grid-template-columns: 40px 120px 120px 120px 120px 120px 120px 150px 200px 200px 120px 120px 120px 100px 120px 120px 100px 200px 120px 120px;
    gap: 10px;
    align-items: center;

    > div {
        margin-bottom: 10px;

        > span {
            display: none;
        }
    }
}
