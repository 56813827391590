.h1 {
    font-weight: bold;
    font-size: var(--font-size-h1);
    line-height: var(--line-height-h1);
    color: var(--black);
}

.noRoles {
    color: var(--white);
    background-color: var(--red);
    padding: 16px;
    margin-bottom: 20px;
    border: 1px solid var(--red);
    border-radius: 4px;
}

.role {
    display: grid !important;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    padding: 20px !important;
    align-items: center;
    margin-right: 0 !important;
    width: 100%;
    text-align: left;
    font-size: var(--font-size-micro) !important;

    img {
        width: 100%;
        height: auto;
    }

    h2 {
        font-size: var(--font-size);
        font-weight: 700;
        cursor: pointer;
    }
}

.logout {
    width: 100%;
    margin-right: 0;

    &:hover {
        background: var(--red);
        color: var(--white);
    }
}

.changeServer {
    width: 100%;
    margin-right: 0;
}
