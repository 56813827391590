.player {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 40px;
    margin-bottom: 64px;

    h2 {
        margin-top: 6px;
        margin-bottom: 9px;
    }
}

.license {
    font-size: 14px;
    line-height: 16px;
    color: var(--dark-gray);
}

.name {
    font-size: 14px;
    line-height: 16px;
    margin: 9px 0 0 0;
    color: var(--gray);
}

.info {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 16px;
    margin-bottom: 60px;
}

.actions {
    list-style: none;
    padding: 0;
    margin: 10px 0 0;

    li {
        display: inline-block;
        margin-right: 20px;
    }

    i {
        position: relative;
        top: 3px;
        font-size: 20px;
    }
}

.chart {
    margin: 0 15px 15px 15px;
}

.number {
    width: 35px;
    font-size: 14px;
    color: var(--gray);
}

.photo {
    padding: 24px;
    background: #fff;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    height: 200px;

    img {
        width: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.position {
    color: var(--dark-gray);
    display: grid;
    grid-template-columns: auto auto 1fr;
    gap: 15px;
    margin-bottom: 16px;
    border-bottom: solid 1px var(--light-gray);
    padding-bottom: 12px;
}

.quickInfo {
    color: var(--dark-gray);
    font-size: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    max-width: 350px;
}

.team {
    color: var(--dark-gray);

    > div {
        margin: 0;
    }

    select {
        font-size: 14px;
        font-weight: normal;
        background: transparent;
    }
}

.list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.groupTitle {
    color: var(--dark-gray);
    font-weight: bold;
}

.column12 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 5px;
}

.column1148 {
    display: grid;
    grid-template-columns: 1fr 1fr 48px;
    gap: 5px;
}

@media only screen and (max-width : 1200px) {
    .list {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width : 700px) {
    .hide {
        display: none;
    }

    .team > div {
        display: inline-block;
    }
}

@media only screen and (max-width : 600px) {
    .player {
        grid-template-columns: 1fr;
    }

    .photo img {
        width: 100px;
        height: auto;
    }

    .list {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width : 450px) {
    .player {
        margin-bottom: 40px;
    }

    .photo {
        text-align: center;

        img {
            width: 100px;
            height: auto;
        }
    }
}

