.Pagination {
  text-align: center;
  list-style: none;
  padding: 0;
  margin: 20px 0;

  li {
    display: inline-block;
    margin: 0 5px;
    a{
      display: inline-block;
      padding: 5px;
      border: solid 1px #EDF0F3;
      border-radius: 4px;
      min-width: 25px;
      background: var(--light-gray);
    }
  }

  .selected a {
    background: var(--primary);
    color: var(--white);
  }
}

