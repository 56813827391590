.submenu {
  display: flex;
  overflow-x: auto;
  width: 100%;
  padding: 0;
  height: 30px;
  margin: 30px 0 0 0;

  li {
    flex: 0 0 auto;
    display: inline-block;
    margin-right: 15px;
    font-size: 14px;
    line-height: 20px;
    color: var(--dark-gray);

    a {
      &:hover {
        text-decoration: none;
      }
    }

    button {
      font-weight: normal;
      background: none;
      border: none;
      color: var(--dark-gray);
      cursor: pointer;

      &:hover {
        color: var(--black-hover)
      }
    }

    .selected {
      font-weight: bold;
      border-bottom: solid 2px var(--dark-gray);
      padding-bottom: 10px;
    }
  }
}

.submenu::-webkit-scrollbar {
  display: none;
}

@media only screen and (min-width : 600px) {
  .submenu {
    //width: calc(100vw - 40px);
  }
}

@media only screen and (min-width : 992px) {
  .submenu {
    width: initial;
  }
}
