.Password {
    margin-bottom: 15px;
}

.Password.form-field {
    margin-bottom: 16px;
}

.Password label {
    margin-bottom: 0;
    padding: 0;
    display: block;
    font-size: var(--font-size-micro);
}

.Password input {
    border: 1px solid var(--light-gray);
    box-sizing: border-box;
    border-radius: 4px;
    font-size: var(--font-size-mini);
    line-height: var(--line-height-mini);
    padding: 12px 16px;
    width: 100%;
    outline: none;
    transition: all 300ms ease-in-out;
    color: var(--black);
    max-width: unset;
}

.Password input:focus {
    border: 1px solid var(--gray);
}

.Password span {
    color: var(--red);
    font-size: 12px;
    font-weight: bold;
}

.Password.invalid label {
    color: var(--red);
}

.Password.invalid Password {
    border: 1px solid var(--red);
}

.indicator {
    margin-top: 5px;
    width: 100%;
    height: 2px;
}
