:export {
  lineColor: var(--dark-gray);
}

.OrgChart {
  border: solid 1px var(--light-gray);
  border-radius: 4px;
  min-height: 600px;
  overflow: hidden;
  width: calc(100vh - 32px);
}

.card {
  background: var(--bg-blue);
  border:solid 1px var(--light-gray);
  border-radius: 4px;
  width: 200px;
  min-height: 100px;
  display: inline-block;
  position: relative;
  padding: 15px;

  .description {
    margin-top: 10px;
  }

  &:hover {
    background: var(--white);
    border:solid 1px var(--primary);

    .button {
      display: inline-block;
    }
  }
}

.button {
  display: none;
  position: absolute;
  color: var(--primary);
  border:solid 1px var(--primary);
  border-radius: 15px;
  width: 28px;
  height: 28px;
  bottom: -13px;
  left: 102px;
  z-index: 2;
  font-size: 17px;
  line-height: 17px;

  &:hover {
    background: var(--primary);
    color: var(--white);
  }
}
