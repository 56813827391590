.fileUpload {
    border: 1px var(--gray) solid;
    border-radius: 4px;
    padding: 50px;
    text-align: center;
    color: var(--dark-gray);
}

.hide {
    display: none;
}
