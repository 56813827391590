.columns21 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;
}

.columns11 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.title {
    margin-bottom: 5px;
    color: #7986AF;
}
