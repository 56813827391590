.Nav {
  display: none;
  min-width: 300px;
  min-height: 100vh;
  background: linear-gradient(235.2deg, #EAEAF0 23.03%, #F3F3F7 85.19%), #EFEFF4;
}

.selected {
  background: #F3F3F7;
}

.Open {
  position: absolute;
  display: inline-block;
  min-height: 100vh;
  top: 0;
  left: 0;
  z-index: 3;
}

.Menu {
  list-style: none;
  padding: 0;
  color: var(--black);

  ul {
    list-style: none;
    padding: 0;
  }

  .item {
    a {
      display: block;
      transition: 0.2s;
      margin: 0 20px;
      padding: 8px 15px;
      position: relative;

      i{
        position: relative;
        top: 3px;
        margin-right: 25px;
      }

      &:hover {
        text-decoration: none;
        background: #f5f4f4;
        border-radius: 4px;
      }
    }
  }

  .submenu {
    margin: 10px 35px 0;
    font-weight: bold;
    cursor: pointer;

    &:after {
      content: " ";
      display: inline-block;
    }

    i {
      transition: 0.2s;
      font-size: 17px;
      font-weight: bold;
      position: relative;
      top: 3px;
      padding: 0 7px 0 3px;
      color: var(--black);
      display: inline-block;
    }
  }

  .collapsed {
    i {
      transform: rotate(-90deg);
      top: 0;
    }
  }

  .separator {
    border-bottom: solid 1px var(--light-gray-hover);
    margin: 5px 0;
  }

  :global(.active) {
    &:before {
      width: 6px;
      height: 40px;
      background: #347FF6;
      border-radius: 0px 4px 4px 0px;
      content: " ";
      display: inline-block;
      position: absolute;
      left: -20px;
      top: 0;
    }
  }
}

.copy {
  font-size: 10px;
  margin: 30px 32px 10px;
  color: #7986AF;
  opacity: 0.5;

  img {
    margin-bottom: 16px;
  }
}

@media only screen and (min-width : 992px) {
  .Nav {
    background: initial;
  }
}
