.exercise {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 20px;
    background: var(--light-gray);
    padding: 30px;

    h2 {
        margin-top: 6px;
    }
}

.image {
    padding: 24px;
    background: #fff;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    height: 200px;

    img {
        width: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.actions {
    list-style: none;
    padding: 0;

    li {
        display: inline-block;
        margin-right: 20px;

        button {
            margin: 0;
        }
    }

    i {
        position: relative;
        top: 3px;
        font-size: 20px;
    }
}

.panel {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    margin-top: 30px;
    color: var(--text-gray);
}

.body {
    padding: 30px 30px 0 30px;

    .title {
        font-weight: bold;
    }

    .group {
        margin: 0 0 20px 0;
    }

    .scheme {
        img {
            width: 100%;
            height: auto;
        }
        background-position: center;
        background-size: contain;
    }
}

.tag {
    border: solid 1px var(--second-bg);
    background: var(--second-bg);
    margin: 0 15px 15px 0;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    color: var(--primary);
    display: inline-block;
}


@media only screen and (max-width : 700px) {
    .exercise {
        display: grid;
        grid-template-columns: none;
    }
}
