.FooterBar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: #fff;
  align-items: center;
  z-index: 10;
}

@media only screen and (min-width: 600px) {
  .FooterBar {
    display: none;
  }
}
