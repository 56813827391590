.Input {
    margin-bottom: 15px;
    font-weight: 500;
}

.Input.form-field {
    margin-bottom: 16px;
}

.Input label {
    margin-bottom: 0;
    padding: 0;
    display: block;
    font-size: var(--font-size-micro);
    color: var(--dark-gray);
}

.Input input {
    border: 1px solid var(--light-gray);
    box-sizing: border-box;
    border-radius: 4px;
    font-size: var(--font-size-mini);
    line-height: var(--line-height-mini);
    padding: 8px 14px;
    width: 100%;
    outline: none;
    transition: all 300ms ease-in-out;
    color: var(--black);
    max-width: unset;

    &::placeholder {
        color: var(--gray);
    }
}

.Input input:focus {
    border: 1px solid var(--light-blue);
}

.Input input:hover {
    border: 1px solid var(--blue);
}

.Input input:active {
    border: 1px solid var(--light-blue);
}


.Input span {
    color: var(--red);
    font-size: 12px;
    font-weight: bold;
}

.Input.invalid label {
    color: var(--red);
}

.Input.invalid input {
    border: 1px solid var(--red);
}
