.hide {
}

.buttons {
  width: 120px;

  button {
    margin: 0;
  }
}

.unHide {
  display: none;
}

.status {
  width: 15px;
  height: 15px;
  background: var(--gray);
  display: inline-block;
  border-radius: 15px;
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.green {
  background: var(--primary);
}

.yellow {
  background: var(--yelow);
}

.firstFilter {
  margin: 0
}

@media only screen and (max-width : 700px) {
  .hide {
    display: none;
  }

  .buttons {
    width: auto;
  }

  .unHide {
    display: block;
  }
}
