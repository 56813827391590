.Settings {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--bg);
  z-index: 10;
  color: var(--black);
  overflow-y: scroll;
}

.Header {
  display: grid;
  grid-template-columns: 20px 1fr 1fr 20px;
  border-bottom: solid 1px var(--light-gray);
  box-shadow: 0px 4px 24px rgba(52, 127, 246, 0.4);
  font-size: 32px;
  padding: 13px 0;
}


.Close {
  text-align: right;
  button {
    border: none;
    background: no-repeat;
    font-size: 20px;
  }
}


.menu {
  list-style: none;
  list-style: none;
  margin: 0;
  padding: 20px;
  border-left: solid 1px #EDF0F3;
  border-right: solid 1px #EDF0F3;
  background: #fff;
  min-height: 100vh;

  li {
    cursor: pointer;
    margin-bottom: 5px;
    padding: 5px;

    i {
      position: relative;
      top: 3px;
      margin-right: 5px;
      color: var(--blue);
    }
  }

  .selected {
    background: var(--white);
    border-radius: 4px;
  }
}

.show {
  display: block;
}

.hide {
  display: none;
}

.loading {
  font-size: 12px;
}

.saved {
  color: var(--primary);
  font-size: 12px;
}

.error {
  color: var(--red);
  font-size: 12px;
}

.content {
  background: var(--white);
  min-height: 100vh;
  padding: 20px;
  border-left: solid 1px var(--light-gray);
  border-right: solid 1px var(--light-gray);

  .title {
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
  }
}

.buttonList {
  list-style: none;
  padding: 0;

  li {
    border: solid 1px var(--light-gray);
    border-radius: 4px;
    margin-bottom: 5px;
    padding: 12px;
    cursor: pointer;
    background: var(--bg);

    &:hover {
      border-color: var(--blue);
      background: var(--bg-blue);
    }
  }
}

.list {
  list-style: none;
  padding: 0;

  li {
    color: var(--black);
    border-bottom: solid 1px var(--light-gray);
    display: grid;
    grid-template-columns: 1fr auto;
    cursor: pointer;
    margin-bottom: 5px;
    padding-bottom: 5px;

    &:hover {
      color: var(--black-hover);
    }
  }
}

.backButton {
  transform: rotate(270deg);
  margin-right: 5px;
  position: relative;
  top: 3px;
}

.link {
  cursor: pointer;

  &:hover {
    color: var(--a-seleted-color);
  }
}

.rights {
  list-style: none;
  padding: 0;

  li {
    display: grid;
    grid-template-columns: 1fr repeat(3, 50px);
    justify-items: center;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px var(--light-gray);

    span {
      &:first-child {
        justify-self: left;
      }
    }
  }
}

.logo {
  display: grid;
  grid-template-columns: 1fr 200px;
  gap: 20px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: var(--scrollbar-bg);
}

*::-webkit-scrollbar {
  width: 6px;
  background-color: var(--scrollbar-bg);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar);
}

.eventGroup {
  border: solid 1px var(--light-gray);
  border-radius: 4px;

  .eventItem:nth-child(even) {
    background: var(--white);
  }

  .eventItem:nth-child(odd) {
    background: var(--bg-blue);
  }
}

.categoryTitle h3 {
  margin-top: 15px;
}

.eventTitles {
  font-size: 12px;
  color: #B2B8CB;
  line-height: 1.6em;
  font-weight: normal;
  padding-left: 18px;
  padding-bottom: 8px;
  text-align: center;
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;

  > div:first-child {
    text-align: left;
  }
}

.eventItem {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  padding: 20px 0 20px 18px;
  text-align: center;

  > div:first-child {
    text-align: left;
    cursor: pointer;
  }
}

.subEventItem {
  > div:first-child {
    margin-left: 20px;
  }
}

.empty {
  color: #536493;
  padding: 20px 18px;
  background: #F5F9FF;
}

@media only screen and (min-width : 992px) {
  .menu {
    display: block;
    margin: 20px;
    border: none;
    background: initial;
  }

  .hide {
    display: block;
  }

  .Header {
    grid-template-columns: 1fr 1fr 3fr 1fr;
  }

  .Content {
    display: grid;
    grid-template-columns: 1fr 1fr 3fr 1fr;
  }

  .backButton {
    display: none;
  }
}
