.mapCanvas {
    width: 100%;
    height: 100%;

    :global(.canvas-container) {
        width: 100% !important;
        height: auto !important;

        :global(canvas) {
            width: 100% !important;
            height: auto !important;
        }
    }

    :global(.samplesvg) {
        :global(ellipse) {
            background: #0EB757;
        }
    }

}