.row {
    display: grid;
    grid-template-columns: 1fr 30px 20px;
    margin-bottom: 10px;

    i {
        width: 4px;
        display: inline-block;
    }

    .up {
        color: var(--primary);
    }

    .down {
        color: var(--error);
    }
}

.edit {
    text-align: center;
}
