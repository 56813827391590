.club {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 40px;
  margin: 30px 0 64px;

  h2 {
    margin-top: 6px;
  }
}

.logo {
  padding: 24px;
  background: #fff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  height: 200px;

  img {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.type {
  font-size: 14px;
  line-height: 16px;
  margin: 30px 0 0 0;
  color: var(--gray);
}

.country {
  font-size: 14px;
  line-height: 16px;
  color: var(--dark-gray);
}

.actions {
  list-style: none;
  padding: 0;
  margin: 35px 0 0;

  li {
    display: inline-block;
    margin-right: 20px;
  }

  i {
    position: relative;
    top: 3px;
    font-size: 20px;
  }
}

.info {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 16px;
  margin-bottom: 60px;
}


.team {
  display: grid;
  grid-template-columns: 2fr repeat(6, 1fr);
  padding: 24px;
  border: solid 1px var(--light-gray);
  border-radius: 4px;
  position: relative;

  div {
    font-size: 20px;
    line-height: 21px;
    color: var(--dark-gray);

    div {
      font-size: 12px;
      line-height: 16px;
      color: var(--gray);
    }
  }

  .title {
    color: var(--black);
  }

  .attendance {
    position: absolute;
    top: 31px;
    left: -12px;

    i {
      background: #fff;
    }

    &:hover {
      i:before {
        color: var(--accent);
      }
    }

  }
}

.trainers {
  border: solid 1px var(--light-gray);
  border-top: none;
  padding: 5px 28px;
  background: var(--bg-blue);
  margin: 0 20px 24px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  color: var(--black);

  span {
    color: var(--gray);
  }
}

.green {
  &:before {
    color: var(--primary);
  }
}

.yellow {
  &:before {
    color: var(--accent);
  }
}


@media only screen and (max-width : 700px) {
  .team {
    grid-template-columns: 1fr auto;

    div {
      display: none;
    }

    .title {
      display: block;
    }

    .attendance {
      display: block;
      position: initial;
    }
  }
}

@media only screen and (max-width : 600px) {
  .logo img {
    width: 100px;
    height: auto;
  }
}

@media only screen and (max-width : 450px) {
  .club {
    display: block;
    margin: 0 0 32px;
  }

  .logo {
    text-align: center;

    img {
      width: 100px;
      height: auto;
    }
  }

  .actions {
    margin: 25px 0 0;
  }
}
