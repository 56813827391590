.number {
    width: 50px;
    text-align: center;
}

.twoColumns {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 30px;
}

.tableRow {
    grid-template-columns: 30px 1fr 70px 50px 1fr 50px;
}

.nameColumn {
    width: 200px;
}



.checkBoxColumn {
    width: 30px;
}

.actions {
    display: flex;
}

.downButton {
    width: 30px;

    span {
        transform: rotate(180deg);
    }
}

.upButton {
    width: 30px;
}
