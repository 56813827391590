#player-possion-chart .draggable, #goal-position-chart .draggable {
    cursor: move;
}

#goal-position-chart ellipse.goal {
    fill: red;
}

#player-possion-chart text, #goal-position-chart text {
    text-anchor: middle;
    font-size: 6px;
    font-family: Arial,sans-serif;
    white-space: pre;
    stroke: none;
    fill: white;
}

#player-possion-chart ellipse.assist, #goal-position-chart ellipse.assist {
    fill: #0094ff;
}
