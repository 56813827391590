.cards {
    margin: 30px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}

.card {
    background-color: var(--light-gray);
    border-radius: 4px;
    max-width: initial;
}

.hide {
}

@media only screen and (max-width : 700px) {
    .hide {
        display: none;
    }
}

@media only screen and (min-width : 600px) {
    .cards {
        grid-template-columns: repeat(3, 1fr);
    }

}

@media only screen and (min-width : 1024px) {
    .cards {
        grid-template-columns: repeat(4, 1fr);
    }
}


@media only screen and (min-width : 1200px) {
    .cards {
        grid-template-columns: repeat(6, 1fr);
    }
}
