.select {
    margin-bottom: 15px;
}

.select label {
    margin-bottom: 0;
    padding: 0;
    display: block;
    font-size: var(--font-size-micro);
    color: var(--dark-gray);
}

:export {
    blackColor: var(--black);
    blueColor: var(--blue);
    primaryColor: var(--primary);
    lightBlueColor: var(--light-blue);
    whiteColor: var(--white);
    fontSize: var(--font-size-mini);
    lineHeight: var(--line-height-mini);
    borderColor: var(--light-gray);
    padding: 0 0 0 6px;
    errorColor: var(--red);
    disabledColor: var(--disabled);
}

.select span {
    color: var(--red);
    font-size: 12px;
    font-weight: bold;
}

.small {
    display: inline-block;
    margin: 0 0 0 24px;

    label {
        font-weight: normal;
        display: inline-block;
        margin-right: 8px;
        font-size: 12px;
        color: var(--dark-gray);
    }

    select {
        border: none;
        font-size: 12px;
        font-weight: bold;
        color: var(--black);
    }
}

:global(.rselect__indicators .rselect__indicator) {
    padding: 6.9px
}

:global(.rselect__menu) {
    z-index: 100;
}

.selectWithAdd {
    display: grid;
    grid-template-columns: 1fr 35px;
    gap: 10px;

    .addButton {
        height: 35px;
        width: 35px;
        margin: 14px 0 0 0;
        background: none;
        border: solid 1px var(--light-gray);
        border-radius: 4px;

        i {
            position: relative;
            top: 2px;

            &:before {
                color: var(--gray);
            }
        }

        &:hover {
            border-color: var(--blue);

            i:before {
                color: var(--dark-gray);
            }
        }
    }
}

