.trainings {
    margin-bottom: 64px;

    h2 {
        margin-top: 6px;
    }
}

.name {
    font-size: 14px;
    line-height: 16px;
    margin: 30px 0 0 0;
    color: var(--gray);
}

.accepted {
    color: var(--ok);
}

.review {
    color: var(--info);
}

.remake {
    color: var(--error);
}

.thisWeek td {
    background: #0da8501f;
}

.hide {
}

.show {
}

@media only screen and (max-width : 700px) {
    .hide {
        display: none;
    }
}

@media only screen and (min-width : 700px) {
    .show {
        display: none;
    }
}
