.list {
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  border: solid 1px var(--light-gray);
  padding: 10px 15px;
  list-style: none;
}

.list li {
  margin-bottom: 3px;
}
