.columns21 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;
}

.columns11 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.columns111 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.submenu {
    margin: 0 0 20px 0;
}

.headerGroup {
    display: grid;
    grid-template-columns: 50px 1fr 1fr;
    gap: 20px;

    font-size: 12px;
    background: #f8fafb;
    padding: 5px 0;
    margin-bottom: 10px;
    text-align: center;
}

.header {
    display: grid;
    grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;

    font-size: 12px;
    color: #B2B8CB;
    line-height: 1.6em;
    padding-bottom: 8px;
}

.row {
    display: grid;
    grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 10px;

    span {
        align-self: center;
    }

    div {
        margin-bottom: 0;
    }
}
