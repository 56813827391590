.group {
    border: solid 1px var(--light-gray);
    border-radius: 4px;
    margin-bottom: 24px;

    ul {
        list-style: none;
        margin: 15px;
        padding: 0 0 15px;
        border-bottom: solid 1px var(--light-gray);
        color: var(--dark-gray);

        &:last-child{
            border-bottom: none;
        }
    }
}

.title {
    padding: 12px 12px;
    color: var(--black);
    border-bottom: solid 1px var(--light-gray);
    background: var(--bg);
}
