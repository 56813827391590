.columns11 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.tabs {
    text-align: center;

    ul {
        padding: 0;
    }
}

.chart {
    margin: 0 15px 15px 15px;
}

.text {
    margin: 15px;
}

.subtitle {
    color: var(--black);
}

.fullRow {
    grid-column: 1 / -1;
}

.dateColumn {
    width: 120px;
}

@media only screen and (max-width : 800px) {
    .columns11 {
        display: block;
    }
}
