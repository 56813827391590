:global(.popup-span .popup) {
    background: #eee;
    padding: 4px;

    &:after {
        display: none;
    }
}

:global(.popup-span .popupItem) {
    font-weight: normal;
    color: var(--black);
    font-size: 14px;

    &:hover {
        text-decoration: none;
    }
}
