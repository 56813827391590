.Progress {
    font-size: var(--font-size-micro);
    line-height: var(--line-height-micro);
    color: var(--primary);
    margin-bottom: 30px;
}

.line {
    margin: 4px 0 8px;
    height: 2px;
    background: var(--light-gray);
}

.line1 {
    height: 2px;
    width: 50%;
    background: var(--primary);
}

.line2 {
    height: 2px;
    width: 100%;
    background: var(--primary);
}
