.tests {
    margin-bottom: 64px;

    h2 {
        margin-top: 6px;
    }
}

.name {
    font-size: 14px;
    line-height: 16px;
    margin: 30px 0 0 0;
    color: var(--gray);
}

.accepted {
    color: var(--ok);
}

.review {
    color: var(--info);
}

.remake {
    color: var(--error);
}

.thisWeek td {
    background: #0da8501f;
}


.empty {
    padding: 20px 0 20px 18px;
    color: var(--black);
    background: var(--bg-blue);
}

.eventGroup {
    border: solid 1px var(--light-gray);
    border-radius: 4px;

    .eventItem:nth-child(even) {
        background: var(--white);
    }

    .eventItem:nth-child(odd) {
        background: var(--bg-blue);
    }
}

.eventTitles {
    font-size: 12px;
    color: #B2B8CB;
    line-height: 1.6em;
    font-weight: normal;
    padding-left: 18px;
    padding-bottom: 8px;
    text-align: center;
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 1fr 1fr;

    > div:first-child {
        text-align: left;
    }
}

.eventItem {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 1fr 1fr;
    padding: 20px 0 20px 18px;
    text-align: center;

    > div:first-child {
        text-align: left;
        cursor: pointer;
    }
}

.subEventItem {
    > div:first-child {
        margin-left: 20px;
    }
}
