.TimePickerBase {
    width: 290px;
    display: grid;
    grid-template-columns: 1fr 20px 1fr;
    gap: 15px 0px;

    .mode {
        grid-column: 1 / -1;
    }

    input {
        width: 100%;
        text-align: center;
        font-size: 15px;
        padding: 6px 14px;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .button {
        margin: 0;
        border: none;

        i {
            display: inline-block;
        }

        &:hover .arrow:before  {
            border-color: #a6a6a6;
        }
    }

    .separator {
        font-size: 15px;
        font-weight: bold;
        justify-self: center;
        align-self: center;
    }

    .hr {
        grid-column: 1 / -1;
        border-bottom: 1px solid #aeaeae;
        margin: 5px 0;
    }
}


.arrow:before {
    border-color: #ccc;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    width: 9px;
}

.arrowUp:before {
    transform: rotate(-45deg);
}

.arrowDown:before {
    transform: rotate(135deg);
}

.buttons {
    grid-column: 1 / -1;
    text-align: center;
}

.error {
    input {
        border: solid 1px var(--red);
        color: var(--red);
    }
}

