.title {
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
}

.link {
    cursor: pointer;

    &:hover {
        color: var(--a-seleted-color);
    }
}

.backButton {
    transform: rotate(270deg);
    margin-right: 5px;
    position: relative;
    top: 3px;
}


