.description {
    margin: 16px 0 56px;
    font-size: var(--font-size-mini);
    line-height: var(--line-height-mini);
    color: var(--black);
}

p {
    font-size: var(--text-second-medium);
    margin: 0;
}
