.teamStat {
    margin-bottom: 64px;

    h2 {
        margin-top: 6px;
    }
}

.name {
    font-size: 14px;
    line-height: 16px;
    margin: 30px 0 0 0;
    color: var(--gray);
}

.info {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 16px;
    margin-bottom: 60px;
    border: solid 1px var(--light-gray);
    border-radius: 4px;
}

.columns11 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.padding {
    padding: 20px;
}

@media only screen and (max-width : 600px) {
    .columns11 {
        grid-template-columns: 1fr;
    }
}
