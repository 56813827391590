.card {
  background: var(--white);
  padding: 20px 20px;
  border-radius: 4px;
  max-width: 160px;
}

.value {
  font-size: 16px;
  line-height: 20px;
  color: var(--black);
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: 1fr auto;
}

.name {
  font-size: 12px;
  line-height: 16px;
  color: var(--dark-gray);
}
