:global(.rdw-option-wrapper) {
    padding: 5px;
    min-width: 25px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-transform: capitalize;
    border-radius: 4px;
}
:global(.rdw-option-wrapper:hover) {
    background: var(--light-blue);
}
:global(.rdw-option-wrapper:active) {
    background: var(--white);
}
:global(.rdw-option-active) {
    background: var(--white);
}
:global(.rdw-option-disabled) {
    opacity: 0.3;
    cursor: default;
}

:global(.rdw-dropdown-wrapper) {
    height: 30px;
    background: white;
    cursor: pointer;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    margin: 0 3px;
    text-transform: capitalize;
    background: white;
}
:global(.rdw-dropdown-wrapper:focus) {
    outline: none;
}
:global(.rdw-dropdown-wrapper:hover) {
    box-shadow: 1px 1px 0px #BFBDBD;
    background-color: #FFFFFF;
}
:global(.rdw-dropdown-wrapper:active) {
    box-shadow: 1px 1px 0px #BFBDBD inset;
}
:global(.rdw-dropdown-carettoopen) {
    height: 0px;
    width: 0px;
    position: absolute;
    top: 35%;
    right: 10%;
    border-top: 6px solid black;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
:global(.rdw-dropdown-carettoclose) {
    height: 0px;
    width: 0px;
    position: absolute;
    top: 35%;
    right: 10%;
    border-bottom: 6px solid black;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
:global(.rdw-dropdown-selectedtext) {
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
    padding: 0 5px;
    font-size: 14px;
}
:global(.rdw-dropdown-optionwrapper) {
    z-index: 100;
    position: relative;
    border: 1px solid #F1F1F1;
    width: 98%;
    background: white;
    border-radius: 2px;
    margin: 0;
    padding: 0;
    max-height: 250px;
    overflow-y: scroll;
}
:global(.rdw-dropdown-optionwrapper:hover) {
    box-shadow: 1px 1px 0px #BFBDBD;
    background-color: #FFFFFF;
}

:global(.rdw-dropdownoption-default) {
    min-height: 25px;
    display: flex;
    align-items: center;
    padding: 0 5px;
}
:global(.rdw-dropdownoption-highlighted) {
    background: #F1F1F1;
}
:global(.rdw-dropdownoption-active) {
    background: #f5f5f5;
}
:global(.rdw-dropdownoption-disabled) {
    opacity: 0.3;
    cursor: default;
}

:global(.rdw-inline-wrapper) {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap
}
:global(.rdw-inline-dropdown) {
    width: 50px;
}
:global(.rdw-inline-dropdownoption) {
    height: 40px;
    display: flex;
    justify-content: center;
}

:global(.rdw-block-wrapper) {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap
}
:global(.rdw-block-dropdown) {
    width: 110px;
}

:global(.rdw-fontsize-wrapper) {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap
}
:global(.rdw-fontsize-dropdown) {
    min-width: 40px;
}
:global(.rdw-fontsize-option) {
    display: flex;
    justify-content: center;
}

:global(.rdw-fontfamily-wrapper) {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap
}
:global(.rdw-fontfamily-dropdown) {
    width: 115px;
}
:global(.rdw-fontfamily-placeholder) {
    white-space: nowrap;
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
}
:global(.rdw-fontfamily-optionwrapper) {
    width: 140px;
}

:global(.rdw-list-wrapper) {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap
}
:global(.rdw-list-dropdown) {
    width: 50px;
    z-index: 90;
}
:global(.rdw-list-dropdownOption) {
    height: 40px;
    display: flex;
    justify-content: center;
}

:global(.rdw-text-align-wrapper) {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap
}
:global(.rdw-text-align-dropdown) {
    width: 50px;
    z-index: 90;
}
:global(.rdw-text-align-dropdownOption) {
    height: 40px;
    display: flex;
    justify-content: center;
}
:global(.rdw-right-aligned-block) {
    text-align: right;
}
:global(.rdw-left-aligned-block) {
    text-align: left !important;
}
:global(.rdw-center-aligned-block) {
    text-align: center !important;
}
:global(.rdw-justify-aligned-block) {
    text-align: justify !important;
}
:global(.rdw-right-aligned-block > div) {
    display: inline-block;
}
:global(.rdw-left-aligned-block > div) {
    display: inline-block;
}
:global(.rdw-center-aligned-block > div) {
    display: inline-block;
}
:global(.rdw-justify-aligned-block > div) {
    display: inline-block;
}

:global(.rdw-colorpicker-wrapper) {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap
}
:global(.rdw-colorpicker-modal) {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 175px;
    height: 175px;
    border: 1px solid #F1F1F1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #BFBDBD;
}
:global(.rdw-colorpicker-modal-header) {
    display: flex;
    padding-bottom: 5px;
}
:global(.rdw-colorpicker-modal-style-label) {
    font-size: 15px;
    width: 50%;
    text-align: center;
    cursor: pointer;
    padding: 0 10px 5px;
}
:global(.rdw-colorpicker-modal-style-label-active) {
    border-bottom: 2px solid #0a66b7;
}
:global(.rdw-colorpicker-modal-options) {
    margin: 5px auto;
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    overflow: scroll;
}
:global(.rdw-colorpicker-cube) {
    width: 22px;
    height: 22px;
    border: 1px solid #F1F1F1;
}
:global(.rdw-colorpicker-option) {
    margin: 3px;
    padding: 0;
    min-height: 20px;
    border: none;
    width: 22px;
    height: 22px;
    min-width: 22px;
    box-shadow: 1px 2px 1px #BFBDBD inset;
}
:global(.rdw-colorpicker-option:hover) {
    box-shadow: 1px 2px 1px #BFBDBD;
}
:global(.rdw-colorpicker-option:active) {
    box-shadow: -1px -2px 1px #BFBDBD;
}
:global(.rdw-colorpicker-option-active) {
    box-shadow: 0px 0px 2px 2px #BFBDBD;
}

:global(.rdw-link-wrapper) {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap
}
:global(.rdw-link-dropdown) {
    width: 50px;
}
:global(.rdw-link-dropdownOption) {
    height: 40px;
    display: flex;
    justify-content: center;
}
:global(.rdw-link-dropdownPlaceholder) {
    margin-left: 8px;
}
:global(.rdw-link-modal) {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 235px;
    height: 205px;
    border: 1px solid #F1F1F1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #BFBDBD;
}
:global(.rdw-link-modal-label) {
    font-size: 15px;
}
:global(.rdw-link-modal-input) {
    margin-top: 5px;
    border-radius: 2px;
    border: 1px solid #F1F1F1;
    height: 25px;
    margin-bottom: 15px;
    padding: 0 5px;
}
:global(.rdw-link-modal-input:focus) {
    outline: none;
}
:global(.rdw-link-modal-buttonsection) {
    margin: 0 auto;
}
:global(.rdw-link-modal-target-option) {
    margin-bottom: 20px;
}
:global(.rdw-link-modal-target-option > span) {
    margin-left: 5px;
}
:global(.rdw-link-modal-btn) {
    margin-left: 10px;
    width: 75px;
    height: 30px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}
:global(.rdw-link-modal-btn:hover) {
    box-shadow: 1px 1px 0px #BFBDBD;
}
:global(.rdw-link-modal-btn:active) {
    box-shadow: 1px 1px 0px #BFBDBD inset;
}
:global(.rdw-link-modal-btn:focus) {
    outline: none !important;
}
:global(.rdw-link-modal-btn:disabled) {
    background: #ece9e9;
}
:global(.rdw-link-dropdownoption) {
    height: 40px;
    display: flex;
    justify-content: center;
}
:global(.rdw-history-dropdown) {
    width: 50px;
}

:global(.rdw-embedded-wrapper) {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap
}
:global(.rdw-embedded-modal) {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 235px;
    height: 180px;
    border: 1px solid #F1F1F1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    justify-content: space-between;
    box-shadow: 3px 3px 5px #BFBDBD;
}
:global(.rdw-embedded-modal-header) {
    font-size: 15px;
    display: flex;
}
:global(.rdw-embedded-modal-header-option) {
    width: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
:global(.rdw-embedded-modal-header-label) {
    width: 95px;
    border: 1px solid #f1f1f1;
    margin-top: 5px;
    background: #6EB8D4;
    border-bottom: 2px solid #0a66b7;
}
:global(.rdw-embedded-modal-link-section) {
    display: flex;
    flex-direction: column;
}
:global(.rdw-embedded-modal-link-input) {
    width: 88%;
    height: 35px;
    margin: 10px 0;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    font-size: 15px;
    padding: 0 5px;
}
:global(.rdw-embedded-modal-link-input-wrapper) {
    display: flex;
    align-items: center;
}
:global(.rdw-embedded-modal-link-input:focus) {
    outline: none;
}
:global(.rdw-embedded-modal-btn-section) {
    display: flex;
    justify-content: center;
}
:global(.rdw-embedded-modal-btn) {
    margin: 0 3px;
    width: 75px;
    height: 30px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}
:global(.rdw-embedded-modal-btn:hover) {
    box-shadow: 1px 1px 0px #BFBDBD;
}
:global(.rdw-embedded-modal-btn:active) {
    box-shadow: 1px 1px 0px #BFBDBD inset;
}
:global(.rdw-embedded-modal-btn:focus) {
    outline: none !important;
}
:global(.rdw-embedded-modal-btn:disabled) {
    background: #ece9e9;
}
:global(.rdw-embedded-modal-size) {
    align-items: center;
    display: flex;
    margin: 8px 0;
    justify-content: space-between;
}
:global(.rdw-embedded-modal-size-input) {
    width: 80%;
    height: 20px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    font-size: 12px;
}
:global(.rdw-embedded-modal-size-input:focus) {
    outline: none;
}

:global(.rdw-emoji-wrapper) {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap
}
:global(.rdw-emoji-modal) {
    overflow: auto;
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-wrap: wrap;
    width: 235px;
    height: 180px;
    border: 1px solid #F1F1F1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #BFBDBD;
}
:global(.rdw-emoji-icon) {
    margin: 2.5px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

:global(.rdw-spinner) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
:global(.rdw-spinner > div) {
    width: 12px;
    height: 12px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
:global(.rdw-spinner .rdw-bounce1) {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
:global(.rdw-spinner .rdw-bounce2) {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}
@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
      }
}

:global(.rdw-image-wrapper) {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap
}
:global(.rdw-image-modal) {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 235px;
    border: 1px solid #F1F1F1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #BFBDBD;
}
:global(.rdw-image-modal-header) {
    font-size: 15px;
    margin: 10px 0;
    display: flex;
}
:global(.rdw-image-modal-header-option) {
    width: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

:global(.rdw-image-modal-header-label) {
    width: 80px;
    background: #f1f1f1;
    border: 1px solid #f1f1f1;
    margin-top: 5px;
}
:global(.rdw-image-modal-header-label-highlighted) {
    background: #6EB8D4;
    border-bottom: 2px solid #0a66b7;
}
:global(.rdw-image-modal-upload-option) {
    width: 100%;
    color: gray;
    cursor: pointer;
    display: flex;
    border: none;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    outline: 2px dashed gray;
    outline-offset: -10px;
    margin: 10px 0;
    padding: 9px 0;
}
:global(.rdw-image-modal-upload-option-highlighted) {
    outline: 2px dashed #0a66b7;
}
:global(.rdw-image-modal-upload-option-label) {
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
}
:global(.rdw-image-modal-upload-option-label span) {
    padding: 0 20px;
}
:global(.rdw-image-modal-upload-option-image-preview) {
    max-width: 100%;
    max-height: 200px;
}
:global(.rdw-image-modal-upload-option-input) {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
:global(.rdw-image-modal-url-section) {
    display: flex;
    align-items: center;
}
:global(.rdw-image-modal-url-input) {
    width: 90%;
    height: 35px;
    margin: 15px 0 12px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    font-size: 15px;
    padding: 0 5px;
}
:global(.rdw-image-modal-btn-section) {
    margin: 10px auto 0;
}
:global(.rdw-image-modal-url-input:focus) {
    outline: none;
}
:global(.rdw-image-modal-btn) {
    margin: 0 5px;
    width: 75px;
    height: 30px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}
:global(.rdw-image-modal-btn:hover) {
    box-shadow: 1px 1px 0px #BFBDBD;
}
:global(.rdw-image-modal-btn:active) {
    box-shadow: 1px 1px 0px #BFBDBD inset;
}
:global(.rdw-image-modal-btn:focus) {
    outline: none !important;
}
:global(.rdw-image-modal-btn:disabled) {
    background: #ece9e9;
}
:global(.rdw-image-modal-spinner) {
    position: absolute;
    top: -3px;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
}
:global(.rdw-image-modal-alt-input) {
    width: 70%;
    height: 20px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    font-size: 12px;
    margin-left: 5px;
}
:global(.rdw-image-modal-alt-input:focus) {
    outline: none;
}
:global(.rdw-image-modal-alt-lbl) {
    font-size: 12px;
}
:global(.rdw-image-modal-size) {
    align-items: center;
    display: flex;
    margin: 8px 0;
    justify-content: space-between;
}
:global(.rdw-image-modal-size-input) {
    width: 40%;
    height: 20px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    font-size: 12px;
}
:global(.rdw-image-modal-size-input:focus) {
    outline: none;
}
:global(.rdw-image-mandatory-sign) {
    color: red;
    margin-left: 3px;
    margin-right: 3px;
}

:global(.rdw-remove-wrapper) {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap
}

:global(.rdw-history-wrapper) {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap
}
:global(.rdw-history-dropdownoption) {
    height: 40px;
    display: flex;
    justify-content: center;
}
:global(.rdw-history-dropdown) {
    width: 50px;
}

:global(.rdw-link-decorator-wrapper) {
    position: relative;
}
:global(.rdw-link-decorator-icon) {
    position: absolute;
    left: 40%;
    top: 0;
    cursor: pointer;
    background-color: white;
}

:global(.rdw-mention-link) {
    text-decoration: none;
    color: #1236ff;
    background-color: #f0fbff;
    padding: 1px 2px;
    border-radius: 2px;
}

:global(.rdw-suggestion-wrapper) {
    position: relative;
}
:global(.rdw-suggestion-dropdown) {
    position: absolute;
    display: flex;
    flex-direction: column;
    border: 1px solid #F1F1F1;
    min-width: 100px;
    max-height: 150px;
    overflow: auto;
    background: white;
    z-index: 100;
}
:global(.rdw-suggestion-option) {
    padding: 7px 5px;
    border-bottom: 1px solid #f1f1f1;
}
:global(.rdw-suggestion-option-active) {
    background-color: #F1F1F1;
}

:global(.rdw-hashtag-link) {
    text-decoration: none;
    color: #1236ff;
    background-color: #f0fbff;
    padding: 1px 2px;
    border-radius: 2px;
}

:global(.rdw-image-alignment-options-popup) {
    position: absolute;
    background: white;
    display: flex;
    padding: 5px 2px;
    border-radius: 2px;
    border: 1px solid #F1F1F1;
    width: 105px;
    cursor: pointer;
    z-index: 100;
}
:global(.rdw-alignment-option-left) {
    justify-content: flex-start;
}
:global(.rdw-image-alignment-option) {
    height: 15px;
    width: 15px;
    min-width: 15px;
}
:global(.rdw-image-alignment) {
    position: relative;
}
:global(.rdw-image-imagewrapper) {
    position: relative;
}
:global(.rdw-image-center) {
    display: flex;
    justify-content: center;
}
:global(.rdw-image-left) {
    display: flex;
}
:global(.rdw-image-right) {
    display: flex;
    justify-content: flex-end;
}
:global(.rdw-image-alignment-options-popup-right) {
    right: 0;
}

:global(.rdw-editor-main) {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    border: solid 1px var(--gray);
    padding: 0 10px;
    border-radius: 0 0 4px 4px;
}
:global(.rdw-editor-toolbar) {
    display: flex;
    justify-content: flex-start;
    background: #F6F6F9;
    flex-wrap: wrap;
    font-size: 15px;
    user-select: none;
    padding: 5px;
    border-radius: 4px;
}
:global(.public-DraftStyleDefault-block) {
    margin: 1em 0;
}
:global(.rdw-editor-wrapper:focus) {
    outline: none;
}
:global(.rdw-editor-wrapper) {
    box-sizing: content-box;
}
:global(.rdw-editor-main blockquote) {
    border-left: 5px solid #f1f1f1;
    padding-left: 5px;
}
:global(.rdw-editor-main pre) {
    background: #f1f1f1;
    border-radius: 3px;
    padding: 1px 10px;
}
