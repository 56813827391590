
.Timeline {
  max-width: 100vw;
}

//.gantt::-webkit-scrollbar {
//  display: none;
//}

.gantt {
  display: grid;
  overflow-x: auto;
  border: solid 1px var(--light-gray);
  border-radius: 4px;
  position: relative;

  .Row {
    &:nth-child(odd) {
      background-color: var(--bg-blue);
    }
  }

  .ganttRow {
    display: grid;
    grid-auto-flow: column;

    .ganttRowFirst {
      padding: 15px;
      color: var(--black);
      z-index: 1;
    }

    .minusIcon {
      border: solid 1px var(--light-gray);
      padding: 0 5px;
      margin-right: 5px;
    }

    .ganttRowBars {
      list-style: none;
      display: grid;
      padding: 9px 0;
      margin: 0;
      grid-gap: 8px 0;
      border-top: 1px solid var(--light-gray);
      li {
        font-weight: 500;
        text-align: left;
        font-size: 14px;
        min-height: 15px;
        background-color: #55de84;
        padding: 5px 12px;
        color: #fff;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        border-radius: 20px;
        margin: 0 5px;

        &.stripes {
          background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255,255,255,.1) 5px, rgba(255,255,255,.1) 12px);
        }

        &:before,
        &:after {
          content: "";
          height: 100%;
          top: 0;
          z-index: 4;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.3);
        }
        &:before {
          left: 0;
        }
        &:after {
          right: 0;
        }

        div {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .ganttRowLines {
    position: absolute;
    height: 100%;
    width: 100%;

    span {
      display: block;
      border-right: 1px solid var(--light-gray);
      &.marker {
        background-color: rgba(10, 52, 68, 0.13);
        z-index: 2;
      }
    }
    &:after {
      grid-row: 1;
      grid-column: 0;
      background-color: #1688b345;
      z-index: 2;
      height: 100%;
    }
  }

  .ganttRowEmpty {
    .ganttRowFirst {
      position: sticky;
      left: 0;
      background: #F6F6F9;
      border-top: 1px solid var(--light-gray);
      box-shadow: 2px 3px 3px 0px rgba(34, 60, 80, 0.20);
    }
  }

  .firstColumn {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  .weekend {
    background: var(--bg-50);
  }

  .current {
    background: var(--bg-75);
  }
}

.Timeline .gantt .header {
  color: var(--gray);
  line-height: 1.6em;
  font-weight: normal;
  z-index: 1;

  .ganttRowFirst {
    position: sticky;
    left: 0;
    background: #F6F6F9;
    box-shadow: 2px 3px 3px 0px rgba(34, 60, 80, 0.20);
  }

  span {
    font-size: 12px;
    padding:8px 18px 8px 8px;
    text-align: center;
  }
}

.controls {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 15px;

  .days {
    text-align: right;
  }

  .calendar {
    align-self: center;
    color: var(--dark-gray);
    span {
      padding: 2px 10px;
      cursor: pointer;
      color: var(--blue);
    }
  }
}

@media only screen and (max-width : 480px) {
  .controls {
    grid-template-columns: 1fr;
  }
  .calendar, .days {
    justify-self: center;
    margin: 10px 0;
  }
}

