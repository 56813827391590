.tbOption {
  display: flex;
  > span {
    margin-right: 5px;
  }
}

:global(.tbDropdown__control) {
  border: none !important;
  padding: 0;
  margin: 0;
  cursor: pointer !important;
}

:global(.tbDropdown__single-value) {
  display: flex;

  > span {
    margin-right: 5px;
  }

  i {
    font-size: 20px;
  }
}

:global(.tbDropdown__value-container) {
  > div:last-child {
    padding: 0;
    margin: 0;
  }
}

:global(.tbDropdown__menu ) {
  min-width: 120px;
  z-index: 10;
  position: fixed;
}

