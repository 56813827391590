.Button {
    margin-top: 16px;
    background: var(--second-bg);
    border: solid 1px var(--second-bg);
    color: var(--primary);
    font-weight: 500;
    border-radius: 4px;
    font-size: 14px;
    line-height: var(--line-height-mini);
    padding: 5.75px 9px;
    margin-right: 16px;
    display: inline-block;
    cursor: pointer;

    span {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 8px;
    }
}

.Button:hover {
    background: var(--white);
    border: solid 1px var(--primary);
}

.Button:focus {
    outline: none;
}

.Button:active {
    border: solid 1px var(--light-blue);
}

.Button:disabled {
    background: #ccc;
    color: var(--gray);
    cursor: not-allowed;
}

.error {
    background: rgba(240, 87, 108, 1);
}

.success {
    background: rgba(161, 240, 69, 1);
}

.primary {
    background: var(--primary);
    color: var(--white);

    span {
        i:before {
            color: #fff;
        }
    }
}

.primary:hover {
    background: var(--green-hover);
    text-decoration: none;
    color: var(--white);
}

.second {
    background: var(--second-bg);
    color: var(--primary);
}

.second:hover {
    color: var(--primary);
    i:before {
        color: var(--primary);
    }
}

.third {
    border: none;
    background: none;
    color: var(--dark-gray);
    padding: 0;
    display: inline-block;
}

.third:hover {
    border: none;
    color: var(--black);
    background: none;
}

.third:active {
    background: none;
    color: var(--dark-gray);
    border: none;
}

.third:disabled {
    background: none;
    color: var(--gray);
    cursor: not-allowed;
}

.danger {
    background: var(--red);
    color: var(--white);
}

.danger:hover {
    background: var(--dark-red);
    text-decoration: none;
    i:before {
        color: var(--white);
    }
}

.warning {
    background: var(--accent);
    color: var(--white);
}

.warning:hover {
    background: var(--dark-accent);
    text-decoration: none;
    i:before {
        color: var(--white);
    }
}

:global(.dark) {
    .primary {
        background: #333538;
        border: #333538;
        color: var(--primary);
    }

    .primary:hover {
        background: #44474c;
    }

    .second {
        background: #333538;
        border: #333538;
        color: #ccc;
    }

    .second:hover {
        background: #44474c;
    }

}
