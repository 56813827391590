.LogoBar {
  padding: 20px 20px;

  a {
    text-decoration: none;
  }
}

.panel {
  display: grid;
  grid-template-columns: 60px auto;
  align-items: center;
  padding: 6px;
  background-color: rgba(245, 249, 255, 0.4);
  box-shadow: 4px 4px 32px rgba(50, 74, 138, 0.05);
  border-radius: 4px;
  color: var(--black);
}

.logo {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
