.players {
    margin-bottom: 64px;

    h2 {
        margin-top: 6px;
    }
}

.name {
    font-size: 14px;
    line-height: 16px;
    margin: 30px 0 0 0;
    color: var(--gray);
}

.birthday {
    display: inline-block;

    span:last-child {
        margin-left: 0;
    }
}

.hide {
}

@media only screen and (max-width : 700px) {
    .hide {
        display: none;
    }
}
